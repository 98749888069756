import React from "react";
import {InputTextarea} from "primereact/inputtextarea";

function ProjectItem({project}) {
  return (
    <div className="surface-0 w-full p-3">
      <div className="font-medium text-3xl text-900 mb-3">{project.name}</div>
      <div className="text-xl uppercase text-500 mb-5">client</div>
      <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Denumire Firmă</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {project.clientCompany.name}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">CUI Firmă</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {project.clientCompany.cui}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Nr. Reg. Comerțului</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {project.clientCompany.commercialRegister}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Calitate firma</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {project.clientCompany.role}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Localitate</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.clientCompany.city}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Note</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.clientCompany.note}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Persoana de contact</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.clientCompany.contactPerson}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Telefon</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.clientCompany.contactPhone}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Email</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.clientCompany.contactEmail}
          </div>
        </li>
      </ul>

      <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-xl uppercase text-500 mt-5">proiect</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Denumire proiect</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{project.name}</div>
        </li>

        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Localitate proiect</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{project.city}</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Persoana de contact în proiect</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {project.contactPerson}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Valoare proiect</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.value}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Stadiu proiect</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.stage}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Telefon</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.contactPhone}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Email</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.contactEmail}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">An începere - an temitare</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.startEnd}
          </div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
          <div className="text-500 w-6 md:w-4 font-medium">Calitate în proiect</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
            {project.roleIn}
          </div>
        </li>
      </ul>
      <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <div className="text-xl uppercase text-500 mt-5">descriere</div>
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <img
            src={`${project.img}`}
            alt="pro"
          />
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
          <p>{project.details}</p>
        </li>
      </ul>
    </div>
  );
}

export default ProjectItem;
