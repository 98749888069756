import React from "react";
import {Link} from "react-router-dom";

const Sidebar = () => {
  return (
    <div
      id="app-sidebar"
      className="surface-section h-full lg:h-auto hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none"
      style={{width: "280px"}}>
      <div className="flex flex-column h-full">
        <div className="overflow-y-auto">
          <ul className="list-none p-3 m-0">
            <li>
              <Link
                to="/auth/companies"
                style={{textDecoration: "none"}}
                className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                <i className="pi pi-star mr-2" />
                <span className="font-medium">Companii</span>
              </Link>
            </li>
            <li>
              <Link
                to="/auth/projects"
                style={{textDecoration: "none"}}
                className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                <i className="pi pi-briefcase mr-2" />
                <span className="font-medium">Proiecte</span>
              </Link>
            </li>
            <li>
              <Link
                to="/auth/adverts"
                style={{textDecoration: "none"}}
                className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                <i className="pi pi-bookmark mr-2" />
                <span className="font-medium">Anunturi</span>
              </Link>
            </li>
          </ul>
          <br />
          <ul className="list-none p-3 m-0">
            <li>
              <Link
                to="/auth/profile"
                style={{textDecoration: "none"}}
                className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                <i className="pi pi-star mr-2" />
                <span className="font-medium">Profil</span>
              </Link>
            </li>
          </ul>
          <br />

          <ul className="list-none p-3 m-0">
            <li>
              <Link
                to="/auth/test"
                style={{textDecoration: "none"}}
                className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                <i className="pi pi-star mr-2" />
                <span className="font-medium">TEST WIP</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
