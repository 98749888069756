import React from "react";
import useAuth from "../hooks/useAuth";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const guestLayout = (props) => {
  return (
    <div className="min-h-screen flex flex-column surface-ground">
      <Header />
      <div className="p-2 flex flex-column flex-auto">
        <div className="   surface-section flex-auto">{props.children}</div>
      </div>
      <Footer />
    </div>
  );
};

const userLayout = (props) => {
  return (
    <div className="flex flex-column surface-ground">
      <Header />
      <div className="min-h-screen flex flex-row ">
        <Sidebar />
        <div className="p-3 flex flex-auto">{props.children}</div>
      </div>
      <Footer />
    </div>
  );
};

const Layout = (props) => {
  const {auth} = useAuth();
  return <div>{auth.accessToken ? userLayout(props) : guestLayout(props)}</div>;
};

export default Layout;
