import React from "react";

const Faq = () => {
  return (
    <div className="surface-section px-4 py-6 md:px-6">
      <div className="text-2xl text-900 font-bold mb-2">
        Intrebari frecvente despre platforma Siscone
      </div>
      <div className="text-700 line-height-3">
        Specificul platformei, avantajele clientilor, intrebari privind functionarea
      </div>

      <div className="grid mt-6">
        <div className="col-12 text-900 md:col-6 font-medium text-lg line-height-3">
          How do I become an expert in web designing?
        </div>
        <div className="col-12 md:col-6 text-700 line-height-3">
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur.
        </div>
      </div>
      <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
      <div className="grid">
        <div className="col-12 text-900 md:col-6 font-medium text-lg line-height-3">
          What is web traffic?
        </div>
        <div className="col-12 md:col-6 text-700 line-height-3">
          Sed cras ornare arcu dui. Placerat in egestas erat imperdiet. Arcu vitae elementum
          curabitur vitae nunc sed velit dignissim.
        </div>
      </div>
      <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
      <div className="grid">
        <div className="col-12 text-900 md:col-6 font-medium text-lg line-height-3">
          How do you know if something is true?
        </div>
        <div className="col-12 md:col-6 text-700 line-height-3">
          In hac habitasse platea dictumst vestibulum. Duis ultricies lacus sed turpis tincidunt id.
          Imperdiet proin fermentum leo vel.
        </div>
      </div>
      <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
      <div className="grid">
        <div className="col-12 text-900 md:col-6 font-medium text-lg line-height-3">
          What is the difference between intuition and an educated guess?
        </div>
        <div className="col-12 md:col-6 text-700 line-height-3">
          Cras ornare arcu dui vivamus arcu felis bibendum ut. Amet purus gravida quis blandit
          turpis cursus in hac habitasse. Leo in vitae turpis massa sed elementum tempus.{" "}
        </div>
      </div>
      <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
      <div className="grid">
        <div className="col-12 text-900 md:col-6 font-medium text-lg line-height-3">
          What are the implications of Occam&apos;s razor principle?
        </div>
        <div className="col-12 md:col-6 text-700 line-height-3">
          Risus nec feugiat in fermentum posuere urna nec. Posuere sollicitudin aliquam ultrices
          sagittis. Ut placerat orci nulla pellentesque dignissim enim.
        </div>
      </div>
    </div>
  );
};

export default Faq;
