import {local} from "../api/axios";

const getAll = () => {
  return local.get("/projects");
};

const get = (id) => {
  return local.get(`/projects/${id}`);
};

const create = (data) => {
  return local.post("/projects", data);
};

const update = (id, data) => {
  return local.put(`/projects/${id}`, data);
};

const remove = (id) => {
  return local.delete(`/projects/${id}`);
};

const removeAll = () => {
  return local.delete(`/projects`);
};

const findByTitle = (title) => {
  return local.get(`/projects?title=${title}`);
};

const projectService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
};

export default projectService;
