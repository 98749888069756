// React, {useState, useEffect} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Rating} from "primereact/rating";
import {Link} from "react-router-dom";
import ProjectsListItemCard from "../../../components/project/ProjectsListItemCard";
import useCommonContext from "../../../hooks/useCommonContext";
import useProject from "../../../hooks/useProject";

const ProjectsList = () => {
  const {projects} = useProject();
  const {fetchErrMsg, isLoading} = useCommonContext();

  return (
    <div className="w-full flex justify-content-center align-items-center">
      <div className="w-full surface-section ">
        <section className="surface-ground flex flex-column px-4 py-5 md:px-6">
          <div className="grid">
            {isLoading && <p>Se încarcă proiectele...</p>}
            {fetchErrMsg && <p className="text-red-400">{`Error: ${fetchErrMsg}`}</p>}
            {!fetchErrMsg &&
              !isLoading &&
              projects.map((project) => (
                <ProjectsListItemCard
                  key={project.id}
                  project={project}
                />
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};
export default ProjectsList;
