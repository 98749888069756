import React from "react";
import {Accordion, AccordionTab} from "primereact/accordion";

const Siscore = () => {
  return (
    <div className="w-full surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="font-bold text-900 text-4xl mb-3">Conceptul de clasificare Siscore</div>
      <div className="font-bold text-900 text-4xl mb-3">
        More reasons people around the world prefer us
      </div>
      <div className="text-700 mb-5 line-height-3">
        Egestas dui id ornare arcu odio. Egestas fringilla phasellus faucibus scelerisque eleifend.
      </div>
      <div className="p-3 border-round surface-50">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6 p-3">
            <div className="text-lg text-900 mb-3 font-medium">Services</div>
            <Accordion>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-cloud text-sm" />
                    </span>
                    <span>Cloud Services</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center j\r
                      ustify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-chart-bar text-sm" />
                    </span>
                    <span>Analytic Dashboard</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center\r
                       justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-key text-sm" />
                    </span>
                    <span>Private Keys</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-file-pdf text-sm" />
                    </span>
                    <span>PDF Export</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-book text-sm" />
                    </span>
                    <span>Documentation</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div className="w-full lg:w-6 p-3">
            <div className="text-lg text-900 mb-3 font-medium">Support</div>
            <Accordion>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-filter text-sm" />
                    </span>
                    <span>Advanced Filtering</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-credit-card text-sm" />
                    </span>
                    <span>Payment Gateway</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-map-marker text-sm" />
                    </span>
                    <span>Device Tracking</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-tags text-sm" />
                    </span>
                    <span>Tag Management</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <span
                      className="block inline-flex align-items-center justify-content-center\r
                       border-circle surface-300 mr-2 text-700"
                      style={{width: "2rem", height: "2rem"}}>
                      <i className="pi pi-eye text-sm" />
                    </span>
                    <span>Privacy Settings</span>
                  </div>
                }>
                <p className="line-height-3">
                  Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant
                  morbi. Sit amet risus nullam eget felis eget. Feugiat pretium nibh ipsum consequat
                  nisl vel pretium.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Siscore;
