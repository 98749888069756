import {InputText} from "primereact/inputtext";
import {useState} from "react";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {http} from "../api/axios";

const REGISTER_URL = "/register";

const Register = () => {
  let [check1, setCheck1] = useState("");
  let [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    cui: "",
  });
  let [accessToken, setAccessToken] = useState();
  let [isSignedUp, setIsSignedUp] = useState();

  const handelFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post(REGISTER_URL, formData).then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        setAccessToken(response.data.accessToken);
        setIsSignedUp(true);
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        console.log(`Error: ${err.message}`);
      }
    }
  };
  const isSigntUpTmp = function () {
    return (
      <div>
        Cerere dumneavoastra de inregistrare a fost preluata va vom notifica in momentul in care
        datele din MFINANTE vor fi procesate
      </div>
    );
  };
  const registerFormTmp = function () {
    return (
      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="grid">
          <div className="col-12 lg:col-2">
            <div className="text-900 font-medium text-xl mb-3">Inregistrare</div>
            <p className="m-0 p-0 text-600 line-height-3 mr-3">
              Odio euismod lacinia at quis risus sed vulputate odio.
            </p>
          </div>
          <div className="col-12 lg:col-10">
            <form
              className="grid formgrid p-fluid"
              onSubmit={handelFormSubmit}>
              <div className="field mb-4 col-12">
                <label
                  htmlFor=""
                  className="font-medium text-900">
                  Prenume
                </label>
                <InputText
                  type="text"
                  id="first_name"
                  onChange={(event) =>
                    setFormData((prev) => ({
                      ...prev,
                      first_name: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="field mb-4 col-12">
                <label
                  htmlFor="phone"
                  className="font-medium text-900">
                  Nume
                </label>
                <InputText
                  type="phone"
                  id="last_name"
                  onChange={(event) =>
                    setFormData((prev) => ({
                      ...prev,
                      last_name: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="field mb-4 col-12">
                <label
                  htmlFor="cui"
                  className="font-medium text-900">
                  Cod unic fiscal
                </label>
                <InputText
                  type="text"
                  id="cui"
                  onChange={(event) =>
                    setFormData((prev) => ({
                      ...prev,
                      cui: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="field mb-4 col-12">
                <label
                  htmlFor="email"
                  className="font-medium text-900">
                  Email
                </label>
                <InputText
                  id="email"
                  type="text"
                  onChange={(event) =>
                    setFormData((prev) => ({
                      ...prev,
                      email: event.target.value,
                    }))
                  }
                />
                {formData.email}
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="password"
                  className="font-medium text-900">
                  Parola
                </label>
                <InputText
                  type="password"
                  id="password"
                  onChange={(event) =>
                    setFormData((prev) => ({
                      ...prev,
                      password: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="password_confirmation"
                  className="font-medium text-900">
                  Confirma parola
                </label>
                <InputText
                  type="password"
                  id="password_confirmation"
                  onChange={(event) =>
                    setFormData((prev) => ({
                      ...prev,
                      password_confirmation: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="field mb-4 col-12">
                <label
                  htmlFor="phone"
                  className="font-medium text-900">
                  Tel
                </label>
                <InputText
                  type="phone"
                  id="phone"
                  onChange={(event) =>
                    setFormData((prev) => ({
                      ...prev,
                      phone: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="field mb-4 col-12">
                <label
                  htmlFor="privacy1"
                  className="font-medium text-900">
                  Privacy
                </label>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="privacy1"
                    checked={check1}
                    onChange={(e) => setCheck1(e.checked)}
                  />
                  <span className="ml-2 text-900">Share my data with contacts</span>
                </div>
              </div>
              <div className="col-12">
                <Button
                  label="Inregistreaza-te"
                  className="w-auto mt-3"
                  onClick={handelFormSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return <div>{isSignedUp ? isSigntUpTmp() : registerFormTmp()}</div>;
};
export default Register;
