import React from "react";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import Title from "../../components/ui/Title";
import CompaniesList from "./companies/CompaniesList";

const Dashboard = () => {
  return (
    <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
      <Title title="Companiile inregistrate" />
      <CompaniesList />
      <div
        className="border-blue-600 lg:mb-0 flex flex-column"
        style={{borderLeft: "8px solid"}}>
        <div className="text-500 ml-3 mr-0 md:mr-3">AdminName</div>
        <div className="text-3xl font-medium text-900 ml-3">Proiecte finalizate</div>
        <div className="text-3xl font-medium text-900 ml-3">SiScore - 25</div>
      </div>

      <div className="surface-ground my-3 px-4 py-5 md:px-6">
        <div className="grid">
          <div className="col-12 md:col-6 xl:col-3 p-3">
            <div
              className="surface-card shadow-2 border-rounded p-3"
              style={{borderRadius: "6px"}}>
              <div className="flex justify-content-between align-items-start">
                <div className="w-9">
                  <div className="text-xl font-medium text-900 mb-2">Linoleum</div>
                  <div className="text-900 font-medium text-xl">
                    <span className="text-500">SiScore:</span> 12
                  </div>
                  <div className="flex justify-content-between pt-3">
                    <Button
                      label="201"
                      icon="pi pi-eye"
                      className="p-button-text p-button-secondary w-6 mr-2"
                    />
                    <Button
                      label="40"
                      icon="pi pi-heart"
                      className="p-button-text p-button-secondary w-6 ml-2"
                    />
                  </div>
                </div>
                <Button
                  icon="pi pi-pencil"
                  className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
              </div>

              <ul className="list-none m-0 p-0">
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Denumire client</span>
                  <span className="text-900 font-medium text-sm">Spitalul Iasi</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Localitate</span>
                  <span className="text-900 font-medium text-sm">Iasi</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Valoare proiect</span>
                  <span className="text-900 font-medium text-sm">23.400 RON</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Perioada proiect</span>
                  <span className="text-900 font-medium text-sm">mai 2021 - sept 2021</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Stadiu proiect</span>
                  <span className="text-900 font-medium text-sm">Finalizat</span>
                </li>
              </ul>
              <p className="mt-0 mb-3 text-600">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in, laboriosam
                rerum sed sequi!
              </p>
              <img
                src="https://picsum.photos/500/400"
                className="mb-3 w-full"
              />
              <div className="flex justify-content-between pt-3">
                <Button
                  label="Arhiveaza"
                  className="p-button-text p-button-secondary w-6 mr-2"
                />
                <Button
                  label="Sterge"
                  className="p-button-text p-button-secondary w-6 ml-2"
                />
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 p-3">
            <div
              className="surface-card shadow-2 border-rounded p-3"
              style={{borderRadius: "6px"}}>
              <div className="flex justify-content-between align-items-start">
                <div className="w-9">
                  <div className="text-xl font-medium text-900 mb-2">Parchet</div>
                  <div className="text-900 font-medium text-xl">
                    <span className="text-500">SiScore:</span> 13
                  </div>
                  <div className="flex justify-content-between pt-3">
                    <Button
                      label="345"
                      icon="pi pi-eye"
                      className="p-button-text p-button-secondary w-6 mr-2"
                    />
                    <Button
                      label="50"
                      icon="pi pi-heart"
                      className="p-button-text p-button-secondary w-6 ml-2"
                    />
                  </div>
                </div>
                <Button
                  icon="pi pi-pencil"
                  className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
              </div>

              <ul className="list-none m-0 p-0">
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Denumire client</span>
                  <span className="text-900 font-medium text-sm">Sala Sporturilor</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Localitate</span>
                  <span className="text-900 font-medium text-sm">Iasi</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Valoare proiect</span>
                  <span className="text-900 font-medium text-sm">34.000 RON</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Perioada proiect</span>
                  <span className="text-900 font-medium text-sm">ian 2022 - ian 2023</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                  <span className="text-600 font-medium text-sm">Stadiu proiect</span>
                  <span className="text-900 font-medium text-sm">Desfasurare</span>
                </li>
              </ul>
              <p className="mt-0 mb-3 text-600">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in, laboriosam
                rerum sed sequi!
              </p>
              <img
                src="https://picsum.photos/500/400"
                className="mb-3 w-full"
              />
              <div className="flex justify-content-between pt-3">
                <Button
                  label="Arhiveaza"
                  className="p-button-text p-button-secondary w-6 mr-2"
                />
                <Button
                  label="Sterge"
                  className="p-button-text p-button-secondary w-6 ml-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="border-blue-600 lg:mb-0 flex flex-column"
        style={{borderLeft: "8px solid"}}>
        <div className="text-500 ml-3 mr-0 md:mr-3">AdminName</div>
        <div className="text-3xl font-medium text-900 ml-3">Anunturi publicate</div>
        <div className="text-3xl font-medium text-900 ml-3">SiScore - 15</div>
      </div>

      <div className="surface-ground flex flex-column my-3 px-4 py-5 md:px-6">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-card shadow-2 p-3 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-green-500 font-medium mb-3">Produse</span>
                  <div className="text-900 font-medium text-xl">
                    <span className="text-500">Actuale:</span> 5
                  </div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{width: "2.5rem", height: "2.5rem"}}>
                  <Link
                    to="/auth/new-advert/product"
                    className="p-ripple flex align-items-center cursor-pointer ">
                    <i className="pi pi-plus-circle text-blue-500 text-xl" />
                  </Link>
                </div>
              </div>
              <span className="text-gray-500 font-medium">
                {" "}
                <span className="text-500">Arhivate</span> 23{" "}
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-card shadow-2 p-3 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-green-500 font-medium mb-3">Servicii</span>
                  <div className="text-900 font-medium text-xl">
                    <span className="text-500">Actuale:</span> 8
                  </div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{width: "2.5rem", height: "2.5rem"}}>
                  <Link
                    to="/auth/new-advert/service"
                    className="p-ripple flex align-items-center cursor-pointer ">
                    <i className="pi pi-plus-circle text-blue-500 text-xl" />
                  </Link>
                </div>
              </div>
              <span className="text-gray-500 font-medium">
                {" "}
                <span className="text-500">Arhivate</span> 17{" "}
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-card shadow-2 p-3 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-green-500 font-medium mb-3">Angajari</span>
                  <div className="text-900 font-medium text-xl">
                    <span className="text-500">Actuale:</span> 4
                  </div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{width: "2.5rem", height: "2.5rem"}}>
                  <Link
                    to="/auth/new-advert/job"
                    className="p-ripple flex align-items-center cursor-pointer ">
                    <i className="pi pi-plus-circle text-blue-500 text-xl" />
                  </Link>
                </div>
              </div>
              <span className="text-gray-500 font-medium">
                {" "}
                <span className="text-500">Arhivate</span> 3{" "}
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-card shadow-2 p-3 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-green-500 font-medium mb-3">Licitatii</span>
                  <div className="text-900 font-medium text-xl">
                    <span className="text-500">Actuale:</span> 15
                  </div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{width: "2.5rem", height: "2.5rem"}}>
                  <Link
                    to="/auth/new-advert/tender"
                    className="p-ripple flex align-items-center cursor-pointer ">
                    <i className="pi pi-plus-circle text-blue-500 text-xl" />
                  </Link>
                </div>
              </div>
              <span className="text-gray-500 font-medium">
                {" "}
                <span className="text-500">Arhivate</span> 25{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
