import {createContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {http} from "../api/axios";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRef = useRef();
  const errRef = useRef();
  const storedUser = localStorage.getItem("userName");
  const [user, setUser] = useState(storedUser);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailIsValid, setUserEmailIsValid] = useState("");
  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordISValid] = useState("");
  const storedUserToken = localStorage.getItem("accessToken");
  const [accessToken, setAccessToken] = useState(storedUserToken);
  const [auth, setAuth] = useState({user, accessToken});
  const [errMsg, setErrMsg] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);

  const LOGIN_URL = "/login";
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (userRef.current != undefined) {
      userRef.current.focus();
    }
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [userEmail, password]);

  useEffect(() => {
    setFormIsValid(userEmail.includes("@") && password.trim().length > 8);
  }, [userEmail, password]);

  let emailChangeHandler = (event) => {
    setUserEmail(event.target.value);
  };

  let passwordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post(
        LOGIN_URL,
        JSON.stringify({
          email: userEmail,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "",
          },
        },
      );

      const user = response?.data?.username;
      setUser(user);
      const token = response?.data?.accessToken;
      setAccessToken(token);
      setAuth({user, accessToken: token});
      localStorage.setItem("accessToken", token);
      localStorage.setItem("userName", user);
      setUserEmail("");
      setPassword("");
      console.log("login", auth);
      // TODO: insert info to console on login
      //   idea - invert log in login-logout???
      navigate(from, {replace: true});
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing username or password");
      } else {
        setErrMsg("Login failed");
      }
      errRef.current.focus();
    }
  };

  const logoutHandler = () => {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("userName", "");
    setAuth({user: "", accessToken: ""});
    // TODO: delete info from console on logout
    //   IMPORTANT
    console.log("logout", auth);
    console.log(accessToken);
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        setUser,
        userEmail,
        setUserEmail,
        password,
        setPassword,
        userRef,
        errMsg,
        userEmailIsValid,
        setUserEmailIsValid,
        passwordIsValid,
        setPasswordISValid,
        formIsValid,
        errRef,
        loginHandler,
        logoutHandler,
        passwordChangeHandler,
        emailChangeHandler,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
