import React from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import Title from "../../../components/ui/Title";
import useAdvert from "../../../hooks/useAdvert";
import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import TagsInput from "../../../components/ui/TagsInput";

function EditAdvert() {
  const {
    advert,
    types,
    inputChangeHandler,
    inputChangeHandlerChild,
    selectedType,
    showTypeProps,
    addAdvert,
    typeSelect,
  } = useAdvert();

  return (
    <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
      <div className="flex justify-content-between">
        <Title title="Adăugare anunț" />
      </div>
      <div className="flex flex-column md:flex-row flex-between w-full p-3">
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="name"
              className="block text-900 font-medium mb-2">
              Denumire
            </label>
            <InputText
              id="name"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandler(e)}
              value={advert.name}
              name="name"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="description"
              className="block text-900 font-medium mb-2">
              Descriere
            </label>
            <InputTextarea
              id="description"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandler(e)}
              value={advert.description}
              name="description"
              required
              rows={3}
              cols={30}
              className="py-3 px-2 text-lg"
            />{" "}
          </div>
          <div className="field">
            <label
              htmlFor="descriptionShort"
              className="block text-900 font-medium mb-2">
              Descriere scurtă
            </label>
            <InputTextarea
              id="descriptionShort"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandler(e)}
              value={advert.descriptionShort}
              name="descriptionShort"
              required
              rows={2}
              cols={30}
              className="py-3 px-2 text-lg"
            />
          </div>

          <TagsInput />

          <div className="field">
            <div className="field-checkbox mr-5">
              <Checkbox
                inputId="advertActual"
                name="advertActual"
                value="advertActual"
                onChange={!advert.actual}
                checked={advert.actual}
              />
              <label htmlFor="advertActual">Anunț valabil</label>
            </div>
            <div className="field-checkbox mr-5">
              <Checkbox
                inputId="advertOffer"
                name="advertOffer"
                value="advertOffer"
                onChange={!advert.offer}
                checked={advert.offer}
              />
              <label htmlFor="advertOffer">Ofertă</label>
            </div>
          </div>
        </div>
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="county"
              className="block text-900 font-medium mb-2">
              Județ
            </label>
            <InputText
              id="county"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "contact")}
              value={advert.contact.county}
              name="county"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="city"
              className="block text-900 font-medium mb-2">
              Oraș
            </label>
            <InputText
              id="city"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "contact")}
              value={advert.contact.city}
              name="city"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="address"
              className="block text-900 font-medium mb-2">
              Adresa
            </label>
            <InputText
              id="address"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "contact")}
              value={advert.contact.address}
              name="address"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="phone"
              className="block text-900 font-medium mb-2">
              Telefon
            </label>
            <InputText
              id="phone"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "contact")}
              value={advert.contact.phone}
              name="phone"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="email"
              className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              id="email"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "contact")}
              value={advert.contact.email}
              name="email"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="web"
              className="block text-900 font-medium mb-2">
              Web
            </label>
            <InputText
              id="web"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "contact")}
              value={advert.contact.web}
              name="web"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
      </div>

      <hr className="my-4" />
      <div className="flex align-items-center">
        {types.map((type) => {
          return (
            <div
              key={type.key}
              className="flex mr-4">
              <RadioButton
                inputId={type.key}
                name="types"
                value={type}
                onChange={typeSelect}
                checked={selectedType.key === type.key}
              />
              <label
                htmlFor={type.key}
                className="ml-2">
                {type.name}
              </label>
            </div>
          );
        })}
      </div>
      <div>{showTypeProps()}</div>

      <hr className="my-4" />
      <div className="flex align-items-center justify-content-end">
        <Button
          label="Vizualizare"
          icon="pi pi-eye"
          className="p-button-outlined p-button-success w-auto mx-3"
        />
        <Button
          label="Publicare"
          icon="pi pi-check"
          className="w-auto mx-3"
          onClick={addAdvert}
        />
      </div>
    </div>
  );
}

export default EditAdvert;
