import React from "react";
import useCompany from "../../hooks/useCompany";

const CompanyItemProfileGeneral = () => {
  const {company} = useCompany();
  return (
    <div className="col-12 md:col-6 p-3">
      <div
        className="surface-card shadow-2 border-rounded p-3"
        style={{borderRadius: "6px"}}>
        <div className="text-xl font-medium text-900 mb-2">Profil General</div>
        <ul className="list-none m-0 p-0">
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Denumire</span>
            <span className="text-900 font-medium text-sm">{company.name}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">CUI</span>
            <span className="text-900 font-medium text-sm">{company.cui}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Nr Registrul Comertului</span>
            <span className="text-900 font-medium text-sm">{company.commercialRegisterNr}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Data inregistrare</span>
            <span className="text-900 font-medium text-sm">{company.registerDate}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Capitel Social</span>
            <span className="text-900 font-medium text-sm">{company.capitalSocial} RON</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Cod CAEN principal</span>
            <span className="text-900 font-medium text-sm">{company.caenMain}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Cad CAEN secundare</span>
            <span className="text-900 font-medium text-sm">
              {company.caenSecondaryAmount}
              Vezi detalii
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Judet</span>
            <span className="text-900 font-medium text-sm">{company.contact.county}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Localitate</span>
            <span className="text-900 font-medium text-sm">{company.contact.city}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Adresa</span>
            <span className="text-900 font-medium text-sm">{company.contact.address}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Telefon</span>
            <span className="text-900 font-medium text-sm">{company.contact.phone}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Email</span>
            <span className="text-900 font-medium text-sm">{company.contact.email}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Web</span>
            <span className="text-900 font-medium text-sm">{company.contact.web}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default CompanyItemProfileGeneral;
