import {local} from "../api/axios";

const getAll = () => {
  return local.get("/adverts");
};

const get = (id) => {
  return local.get(`/adverts/${id}`);
};

const create = (data) => {
  return local.post("/adverts", data);
};

const update = (id, data) => {
  return local.put(`/adverts/${id}`, data);
};

const remove = (id) => {
  return local.delete(`/adverts/${id}`);
};

const removeAll = () => {
  return local.delete(`/adverts`);
};

const findByTitle = (title) => {
  return local.get(`/adverts?title=${title}`);
};

const advertService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
};

export default advertService;
// ok
