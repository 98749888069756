import React from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";

const Contact = () => {
  return (
    <div className="surface-section  w-full px-4 py-8 md:px-6 lg:px-8">
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="p-fluid pr-0 md:pr-6">
            <div className="field">
              <label
                htmlFor="name"
                className="font-medium">
                Nume
              </label>
              <InputText
                id="name"
                className="py-3 px-2 text-lg"
              />
            </div>
            <div className="field">
              <label
                htmlFor="email1"
                className="font-medium">
                Email
              </label>
              <InputText
                id="email1"
                className="py-3 px-2 text-lg"
              />
            </div>
            <div className="field">
              <label
                htmlFor="company"
                className="font-medium">
                Companie
              </label>
              <InputText
                id="company"
                className="py-3 px-2 text-lg"
              />
            </div>
            <div className="field">
              <label
                htmlFor="message"
                className="font-medium">
                Mesaj
              </label>
              <InputTextarea
                id="message"
                rows={6}
                autoResize
                className="py-3 px-2 text-lg"
              />
            </div>
            <Button
              label="Trimite mesaj"
              icon="pi pi-send"
              className="w-auto"
            />
          </div>
        </div>
        <div
          className="col-12 md:col-6 bg-no-repeat bg-right-bottom"
          style={{
            backgroundImage: "url('assets/images/blocks/contact/contact-1.png')",
          }}>
          <div className="text-900 text-2xl font-medium mb-6">Date de contact</div>
          <div className="text-700 line-height-3 mb-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </div>
          <a
            className="inline-flex align-items-center text-blue-600 font-bold no-underline\r
           cursor-pointer">
            <span className="mr-3">Vizionare adresa pe Google Maps</span>
            <i className="pi pi-arrow-right" />
          </a>
          <ul className="list-none p-0 m-0 mt-6 text-700">
            <li className="flex align-items-center mb-3">
              <i className="pi pi-phone mr-2" />
              <span>+40 123456789</span>
            </li>
            <li className="flex align-items-center mb-3">
              <i className="pi pi-twitter mr-2" />
              <span>@siscone</span>
            </li>
            <li className="flex align-items-center">
              <i className="pi pi-inbox mr-2" />
              <span>contact@siscone.ro</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
