import Logo from "../assets/images/logo.png";
import {Link} from "react-router-dom";

export default function Footer() {
  return (
    <div className="surface-section px-4 md:px-6 lg:px-8">
      <div className="border-top-1 border-300 pt-5 text-center">
        <img
          src={Logo}
          alt="Logo"
          height="36"
        />
        <ul
          className="list-none p-0 mx-0 my-5 flex justify-content-center flex-column \r
        align-items-center lg:flex-row">
          <li>
            <Link
              to="text"
              className="text-600 cursor-pointer line-height-3 lg:mr-5">
              Despre
            </Link>
          </li>
          <li>
            <Link
              to="text"
              className="text-600 cursor-pointer line-height-3 lg:mr-5">
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="text"
              className="text-600 cursor-pointer line-height-3 lg:mr-5">
              Devina partener
            </Link>
          </li>
          <li>
            <Link
              to="text"
              className="text-600 cursor-pointer line-height-3 lg:mr-5">
              Cariera
            </Link>
          </li>
        </ul>
        <div className="flex align-items-center justify-content-center mb-5">
          <Link
            to="text"
            className="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block\r
             inline-flex justify-content-center align-items-center mr-5"
            style={{width: "2.5rem", height: "2.5rem"}}>
            <i className="pi pi-twitter" />
          </Link>
          <Link
            to="Text"
            className="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block\r
             inline-flex justify-content-center align-items-center mr-5"
            style={{width: "2.5rem", height: "2.5rem"}}>
            <i className="pi pi-facebook" />
          </Link>
          <Link
            to="text"
            className="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block\r
             inline-flex justify-content-center align-items-center"
            style={{width: "2.5rem", height: "2.5rem"}}>
            <i className="pi pi-github" />
          </Link>
        </div>
        <div className="text-center">
          <Link
            to="text"
            className="mr-5 text-sm text-600 cursor-pointer">
            Privacy Policy
          </Link>
          <Link
            to="text"
            className="text-sm text-600 cursor-pointer">
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  );
}
