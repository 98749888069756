import React from "react";
import useAdvert from "../../../hooks/useAdvert";
import useCommonContext from "../../../hooks/useCommonContext";
import AdvertsListCategoryCard from "../../../components/advert/AdvertsListCategoryCard";

const AdvertsListCategories = () => {
  const {advert, adverts, addAdvert, newAdvertHandler, types} = useAdvert();
  const {fetchErrMsg, isLoading} = useCommonContext();

  return (
    <div className="w-full flex justify-content-center align-items-center">
      <div className="w-full surface-section ">
        <section className="surface-ground flex flex-column px-4 py-5 md:px-6">
          <div className="grid">
            {isLoading && <p>Se încarcă anunțurile...</p>}
            {fetchErrMsg && <p className="text-red-400">{`Error: ${fetchErrMsg}`}</p>}
            {!fetchErrMsg &&
              !isLoading &&
              types.map((type) => (
                <AdvertsListCategoryCard
                  key={type.key}
                  type={type}
                />
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdvertsListCategories;
