import {Route} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Siscore from "./pages/Siscore";
import News from "./pages/News";
import Terms from "./pages/Terms";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Missing from "./pages/Missing";
import Unauthorized from "./pages/Unauthorized";
import Dashboard from "./pages/auth/Dashboard";
import Profile from "./pages/auth/Profile";
import Projects from "./pages/auth/projects/Projects";
import EditProject from "./pages/auth/projects/EditProject";
import Adverts from "./pages/auth/adverts/Adverts";
import EditAdvert from "./pages/auth/adverts/EditAdvert";
import Companies from "./pages/auth/companies/Companies";
import EditCompany from "./pages/auth/companies/EditCompany";
import Test from "./components/Test";

export const PublicRoutes = [
  <Route
    path="/"
    element={<Home />}
    key="route-home"
  />,
  <Route
    path="siscore"
    element={<Siscore />}
    key="route-siscore"
  />,
  <Route
    path="about"
    element={<About />}
    key="route-about"
  />,
  <Route
    path="news"
    element={<News />}
    key="route-news"
  />,
  <Route
    path="terms"
    element={<Terms />}
    key="route-terms"
  />,
  <Route
    path="faq"
    element={<Faq />}
    key="route-faq"
  />,
  <Route
    path="contact"
    element={<Contact />}
    key="route-contact"
  />,
  <Route
    path="register"
    element={<Register />}
    key="route-register"
  />,
  <Route
    path="login"
    element={<Login />}
    key="route-login"
  />,
  <Route
    path="unauthorized"
    element={<Unauthorized />}
    key="route-unauthorized"
  />,

  <Route
    path="*"
    element={<Missing />}
    key="route-missing"
  />,
];

export const AuthRoutes = [
  <Route
    path="/auth/dashboard"
    element={<Dashboard />}
    key="route-dashboard"
  />,
  <Route
    path="/auth/projects"
    element={<Projects />}
    key="route-project-list"
  />,
  <Route
    path="/auth/new-project"
    element={<EditProject />}
    key="route-new-project"
  />,
  <Route
    path="/auth/projects/edit/:id"
    element={<EditProject />}
    key="route-project-edit"
  />,

  <Route
    path="/auth/companies"
    element={<Companies />}
    key="route-companies"
  />,
  <Route
    path="/auth/new-company"
    element={<EditCompany />}
    key="route-new-company"
  />,
  <Route
    path="/auth/companies/edit/:id"
    element={<EditCompany />}
    key="route-project-edit"
  />,

  <Route
    path="/auth/adverts"
    element={<Adverts />}
    key="route-adverts-list"
  />,
  <Route
    path="/auth/new-advert"
    element={<EditAdvert />}
    key="route-new-advert"
  />,
  <Route
    path="/auth/adverts/edit/:id"
    element={<EditAdvert />}
    key="route-project-edit"
  />,

  <Route
    path="/auth/profile"
    element={<Profile />}
    key="route-profile"
  />,
  <Route
    path="/auth/test"
    element={<Test />}
    key="route-test"
  />,
];
