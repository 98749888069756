import React from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import useAdvert from "../../hooks/useAdvert";

function EditProduct() {
  const {advert, inputChangeHandlerChild, detailCheckHandler, selectedDetails} = useAdvert();

  return (
    <div className="py-5 ">
      <div className="flex flex-column md:flex-row flex-between w-full">
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="subcategory"
              className="block text-900 font-medium mb-2">
              Subcategorie produs
            </label>
            <InputText
              id="subcategory"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "product")}
              value={advert.product.subcategory}
              name="subcategory"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="price"
              className="block text-900 font-medium mb-2">
              Pret
            </label>
            <InputText
              id="price"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "product")}
              value={advert.product.price}
              name="price"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="warranty"
              className="block text-900 font-medium mb-2">
              Garanție
            </label>
            <InputText
              id="warranty"
              type="number"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e)}
              value={advert.product.warranty}
              name="warranty"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="valability"
              className="block text-900 font-medium mb-2">
              Termen valabilitate
            </label>
            <InputText
              id="valability"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "product")}
              value={advert.product.valability}
              name="valability"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="storageAddress"
              className="block text-900 font-medium mb-2">
              Adresa depozitarii
            </label>
            <InputText
              id="storageAddress"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "product")}
              value={advert.contact.address}
              name="storageAddress"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="minBuy"
              className="block text-900 font-medium mb-2">
              Cantitate minima
            </label>
            <InputText
              id="minBuy"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "product")}
              value={advert.product.minBuy}
              name="minBuy"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="age"
              className="block text-900 font-medium mb-2">
              Vechime produs
            </label>
            <InputText
              id="age"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "product")}
              value={advert.product.age}
              name="age"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
      </div>
      {/* {advert.product.img.length > 0 ? (*/}
      {/*  <div className="flex flex-column">*/}
      {/*    <div className="flex align-items-center mb-4">*/}
      {/*      <span className="text-xl font-medium text-900 mr-5">Foto produs</span>*/}
      {/*      <Button*/}
      {/*        icon="pi pi-upload"*/}
      {/*        className="p-button-rounded p-button-outlined"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="grid">*/}
      {/*      {advert.product.img.map((item, index) => {*/}
      {/*        return (*/}
      {/*          <div*/}
      {/*            className="col-12 md:col-6 xl:col-3 p-3"*/}
      {/*            key={index}>*/}
      {/*            <div*/}
      {/*              className="surface-card shadow-2 border-rounded p-3"*/}
      {/*              style={{borderRadius: "6px"}}>*/}
      {/*              <img*/}
      {/*                src={item.path}*/}
      {/*                className="mb-3 w-full"*/}
      {/*              />*/}
      {/*              <div className="flex justify-content-between align-items-start">*/}
      {/*                <div>*/}
      {/*                  <div className="text-xl font-medium text-900 mb-2">{item.title}</div>*/}
      {/*                  <p className="mt-0 mb-3 text-600">{item.text}</p>*/}
      {/*                </div>*/}
      {/*                <Button*/}
      {/*                  icon="pi pi-download"*/}
      {/*                  className="p-button-rounded p-button-text"*/}
      {/*                />*/}
      {/*                <Button*/}
      {/*                  icon="pi pi-trash"*/}
      {/*                  className="p-button-rounded p-button-text"*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/* ) : (*/}
      {/*  <div className=" mb-3 lg:mb-0 flex md:align-items-center md:justify-content-center flex-column  pb-4">*/}
      {/*    <div className="text-500 mb-3">Adaugati fotografie pentru noul serviciu</div>*/}
      {/*    <Button*/}
      {/*      label="Adauga foto"*/}
      {/*      className="p-button-outlined "*/}
      {/*    />*/}
      {/*  </div>*/}
      {/* )}*/}

      <div className="flex align-items-center">
        <span className="w-2 text-xl font-medium text-900 mr-5 ">Detalii</span>

        <div className="grid mt-4 ">
          {advert.product.details.map((detail) => {
            return (
              <div
                className="field-checkbox mr-5"
                key={detail.key}>
                <Checkbox
                  inputId={detail.key}
                  name="detail"
                  value={detail}
                  onChange={detailCheckHandler}
                  checked={selectedDetails.some((item) => item.key === detail.key)}
                />
                <label htmlFor={detail.key}>{detail.name}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
