import {createContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import companyData from "../services/companyService";
import useCommonContext from "../hooks/useCommonContext";

const CompanyContext = createContext({});

export const CompanyProvider = ({children}) => {
  const navigate = useNavigate();
  const {setFetchErrMsg, setIsLoading, setErrMsg, errRef, setShowDetails} = useCommonContext();

  const companyInitial = {
    name: "",
    cui: 1,
    commercialRegisterNr: "J22 / 402 / 2023",
    registerDate: "25.12.1014",
    caenMain: "4120",
    caenSecondaryAmount: "24",
    capitalSocial: 1000,
    payVat: false,
    contact: {
      county: "Iasi",
      city: "Iasi",
      address: "Adressa ANAF",
      phone: "+40 232 123456",
      email: "info@axe.ro",
      web: "Axe-business.ro",
    },
    shareholders: [
      {
        name: "Olaru Rodica",
        share: "60%",
        position: "Administrator",
      },
      {
        name: "Doctor pardosea",
        share: "40%",
        position: "Administrator",
      },
    ],
    legal: {
      monitorOccurs: "1",
      monitorLast: "2014",
      justiceOccurs: "Nu Sunt",
      justiceLast: "Nu Sunt",
    },
    financial: {
      fiscalValue: 1751887,
      profit: 41353,
      companyAge: 8,
      outstandingObligations: "Nu Sunt",
      bigDeals: 5,
      currentAssets: 228443,
      fixedAssets: 5713,
      liabilities: 246073,
      capital: 50944,
    },
    siscoreOfficial: {
      fiscalValue: 17,
      profit: 4,
      companyAge: 8,
      outstandingObligations: 17,
      bigDeals: 5,
      currentAssets: 23,
      fixedAssets: 6,
      liabilities: -24,
      capital: 51,
    },
    siscoreGained: {
      projects: 17,
    },
    siscoreGlobal: 10,
    views: 20,
    likes: 7,
    projectsNumber: 2,
    advertsNumber: 5,
    jobsNumber: 1,
    auctionsNumber: 0,
    isVerified: false,
  };

  const [company, setCompany] = useState(companyInitial);
  const [companies, setCompanies] = useState([]);

  // update data using company state object
  // TODO need explanation
  const togglePayVat = () => {
    company.payVat = !company.payVat;
    setCompany({...company, payVat: company.payVat});
    console.log("togglePayVat", company.payVat);
  };

  const newCompanyHandler = () => {
    setCompany(companyInitial);
    navigate("/auth/new-company");
  };

  const companyEditHandler = (id) => {
    setShowDetails(true);
    fetchCompany(id);
  };

  // to handle inputdata for nested object, like company.financial.profit - add value for profit
  const inputChangeHandlerChildObj = (event) => {
    const {name, value} = event.target;
    setCompany((company) => {
      return {
        ...company,
        childObj: {
          ...company.childObj,
          [name]: value,
        },
      };
    });
  };

  const inputChangeHandler = (event) => {
    const {name, value} = event.target;
    console.log(name, value);
    setCompany({...company, [name]: value});
  };

  const fetchCompany = async (id) => {
    try {
      const response = await companyData.get(id);
      setCompany(response.data);
      setFetchErrMsg(null);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        setFetchErrMsg(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await companyData.getAll();
      setCompanies(response.data);
      setFetchErrMsg(null);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        setFetchErrMsg(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("/companies/edit")) {
      let params = window.location.pathname.split("/");
      let id = params.slice(-1);
      fetchCompany(+id);
    }
    setTimeout(() => {
      fetchCompanies();
    }, 500);
  }, []);

  const addCompany = async (e) => {
    e.preventDefault();
    try {
      await companyData.create(company);
      fetchCompanies();
      setShowDetails(true);
      navigate("/auth/companies");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing company");
      } else {
        setErrMsg("Fetch failed");
      }
      errRef.current.focus();
    }
  };

  const updateCompany = async (e) => {
    e.preventDefault();
    try {
      await companyData.update(company.id, company);

      fetchCompanies();
      navigate("/auth/companies");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing company");
      } else {
        setErrMsg("Fetch failed");
      }
      errRef.current.focus();
    }
  };

  const deleteCompany = async (id) => {
    try {
      await companyData.remove(id);
      setShowDetails(false);
      fetchCompanies();
      navigate("/auth/companies");
      console.log("companiesLength_DEL", companies.length);

      // location.reload();
      //    alternative of useEffect dependancy
    } catch (err) {
      console.log(`Error:${err.message}`);
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await companyData.getAll();
        setCompanies(response.data);
        setFetchErrMsg(null);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          setFetchErrMsg(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    setTimeout(() => {
      fetchCompanies();
    }, 500);
  }, [companies.length]);
  // useEffect runs twice in React v18 because of strict mode
  //  useEffect is correct comparing to location.reload
  // FIXME fetching error: second time - strictMode, third - dependency

  return (
    <CompanyContext.Provider
      value={{
        companies,
        setCompanies,
        company,
        setCompany,
        togglePayVat,
        newCompanyHandler,
        // companyEditHandler,
        inputChangeHandler,
        addCompany,
        deleteCompany,
        updateCompany,
      }}>
      {children}
    </CompanyContext.Provider>
  );
};
export default CompanyContext;
