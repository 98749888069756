import React from "react";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import useCompany from "../../hooks/useCompany";

const CompanyItemProfileAssociates = () => {
  const {company} = useCompany();

  return (
    <div className="col-12 md:col-6 p-3">
      <div
        className="surface-card shadow-2 border-rounded p-3"
        style={{borderRadius: "6px"}}>
        <div className="text-xl font-medium text-900 mb-2">Profil Asociati</div>
        <ul className="list-none m-0 p-0">
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Nume</span>
            <span className="text-600 font-medium text-sm">%</span>
            <span className="text-600 font-medium text-sm">Functia</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-900 font-medium text-sm ">{company.shareholders[0].name}</span>
            <span className="text-900 font-medium text-sm ">{company.shareholders[0].share}</span>
            <span className="text-900 font-medium text-sm ">
              {company.shareholders[0].position}
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-900 font-medium text-sm">{company.shareholders[1].name}</span>
            <span className="text-900 font-medium text-sm ">{company.shareholders[1].share} </span>
            <Link
              to="/auth/#"
              style={{textDecoration: "none"}}>
              <Button
                icon="pi pi-info"
                className="p-button-outlined p-button-rounded mr-3 "
              />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default CompanyItemProfileAssociates;
