import React from "react";
import useAdvert from "../../../hooks/useAdvert";
import useCommonContext from "../../../hooks/useCommonContext";
import AdvertsListItemCard from "../../../components/advert/AdvertsListItemCard";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";

const AdvertsList = () => {
  const {advert, adverts, addAdvert, newAdvertHandler, filteredAdverts} = useAdvert();
  const {fetchErrMsg, isLoading} = useCommonContext();

  return (
    <section className="grid">
      {isLoading && <p>Se încarcă anunțurile...</p>}
      {fetchErrMsg && <p className="text-red-400">{`Error: ${fetchErrMsg}`}</p>}
      {
        !fetchErrMsg &&
        !isLoading &&
        // TODO updatelisting cetegories
        filteredAdverts.length > 0 ? (
          filteredAdverts.map((advert) => (
            <AdvertsListItemCard
              key={advert.id}
              advert={advert}
            />
          ))
        ) : (
          <div className="flex w-full mt-5 justify-content-center">
            <div className="surface-card border-round shadow-2 p-4 self-center">
              <div className="text-900 font-medium mb-3 text-xl">
                Nu aveti anunturi de acest tip
              </div>

              <Link
                to="/auth/new-advert"
                className="p-ripple flex align-items-center cursor-pointer"
                style={{textDecoration: "none"}}>
                <Button label="Adaugare anunț" />
              </Link>
            </div>
          </div>
        )
        // adverts.map((advert) => (
        //   <AdvertsListItemCard
        //     key={advert.id}
        //     advert={advert}
        //   />
        // )
        // )
      }
    </section>
  );
};

export default AdvertsList;
