import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {Link} from "react-router-dom";
import useAdvert from "../../hooks/useAdvert";

function EditJob(props) {
  const {advert, inputChangeHandlerChild, detailCheckHandler, selectedDetails} = useAdvert();

  return (
    <div className="py-5">
      <div className="flex flex-column md:flex-row flex-between w-full p-3">
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="corList"
              className="block text-900 font-medium mb-2">
              Lista COR
            </label>
            <InputText
              id="corList"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "job")}
              value={advert.job.corList}
              name="name"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="name"
              className="block text-900 font-medium mb-2">
              Pozitie
            </label>
            <InputText
              id="name"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "job")}
              value={advert.job.position}
              name="name"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="studies"
              className="block text-900 font-medium mb-2">
              Studii
            </label>
            <InputText
              id="studies"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "job")}
              value={advert.job.studies}
              name="studies"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="salary"
              className="block text-900 font-medium mb-2">
              Salar
            </label>
            <InputText
              id="salary"
              type="number"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "job")}
              value={advert.job.salary}
              name="salary"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="term"
              className="block text-900 font-medium mb-2">
              Termen contract
            </label>
            <InputText
              id="term"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "job")}
              value={advert.job.term}
              name="term"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="experience"
              className="block text-900 font-medium mb-2">
              Experienta
            </label>
            <InputText
              id="experience"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "job")}
              value={advert.job.experience}
              name="experience"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
      </div>

      <div className="flex align-items-center">
        <span className="w-2 text-xl font-medium text-900 mr-5 ">Detalii</span>

        <div className="grid mt-4 ">
          {advert.job.details.map((detail) => {
            return (
              <div
                className="field-checkbox mr-5"
                key={detail.key}>
                <Checkbox
                  inputId={detail.key}
                  name="detail"
                  value={detail}
                  onChange={detailCheckHandler}
                  checked={selectedDetails.some((item) => item.key === detail.key)}
                />
                <label htmlFor={detail.key}>{detail.name}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EditJob;
