import React from "react";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import useAdvert from "../../hooks/useAdvert";

function EditTender() {
  const {advert, inputChangeHandlerChild, detailCheckHandler, selectedDetails} = useAdvert();

  return (
    <div className="py-5">
      <div className="flex flex-column md:flex-row flex-between w-full p-3">
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="name"
              className="block text-900 font-medium mb-2">
              Categorie
            </label>
            <InputText
              id="name"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "tender")}
              value={advert.tender.subcategory}
              name="name"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="type"
              className="block text-900 font-medium mb-2">
              Tip licitatie
            </label>
            <InputText
              id="type"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "tender")}
              value={advert.tender.type}
              name="type"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="price"
              className="block text-900 font-medium mb-2">
              Pret minim / maxim
            </label>
            <InputText
              id="price"
              type="number"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "tender")}
              value={advert.tender.price}
              name="price"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="step"
              className="block text-900 font-medium mb-2">
              Pas licitatie
            </label>
            <InputText
              id="step"
              type="number"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "tender")}
              value={advert.tender.step}
              name="step"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="startDate"
              className="block text-900 font-medium mb-2">
              Termen incepere
            </label>
            <InputText
              id="startDate"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "tender")}
              value={advert.tender.startDate}
              name="startDate"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="endDate"
              className="block text-900 font-medium mb-2">
              Termen finalizare
            </label>
            <InputText
              id="endDate"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "tender")}
              value={advert.tender.endDate}
              name="endDate"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="victoryCondition"
              className="block text-900 font-medium mb-2">
              Conditii atribuire castigator
            </label>
            <InputText
              id="victoryConditions"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "tender")}
              value={advert.tender.victoryConditions}
              name="victoryConditions"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
      </div>
      <div className="flex align-items-center">
        <span className="w-2 text-xl font-medium text-900 mr-5 ">Detalii</span>
        <div className="grid mt-4 ">
          {advert.tender.details.map((detail) => {
            return (
              <div
                className="field-checkbox mr-5"
                key={detail.key}>
                <Checkbox
                  inputId={detail.key}
                  name="detail"
                  value={detail}
                  onChange={detailCheckHandler}
                  checked={selectedDetails.some((item) => item.key === detail.key)}
                />
                <label htmlFor={detail.key}>{detail.name}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EditTender;
