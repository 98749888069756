import React from "react";
import {Button} from "primereact/button";
import Title from "../../../components/ui/Title";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import useCompany from "../../../hooks/useCompany";

const EditCompany = () => {
  const {company, togglePayVat, inputChangeHandler, addCompany, updateCompany} = useCompany();

  return (
    <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
      {/* <Title title="Adaugare companie" />*/}
      <div
        className="border-blue-600 mb-3 lg:mb-0"
        style={{borderLeft: "8px solid"}}>
        {company.id ? <Title title="Modificare companie" /> : <Title title="Adaugare companie" />}
      </div>
      <label
        htmlFor="companyname"
        className="block text-900 font-medium mb-2">
        Denumire companie:
      </label>
      <InputText
        id="name"
        type="text"
        autoComplete="off"
        onChange={(e) => inputChangeHandler(e)}
        value={company.name}
        name="name"
        required
        className="w-full mb-3 p-3"
      />
      <label
        htmlFor="cui"
        className="block text-900 font-medium mb-2">
        CUI:
      </label>
      <InputText
        id="cui"
        type="number"
        autoComplete="off"
        onChange={(e) => inputChangeHandler(e)}
        value={company.cui}
        name="cui"
        required
        className="w-full mb-3 p-3"
      />
      <label
        htmlFor="comRegisterNr"
        className="block text-900 font-medium mb-2">
        Registrul comertului:
      </label>
      <InputText
        type="text"
        id="comRegisterNr"
        autoComplete="off"
        onChange={(e) => inputChangeHandler(e)}
        value={company.commercialRegisterNr}
        name="commercialRegisterNr"
        required
        className="w-full mb-3 p-3"
      />
      <Checkbox
        id="payVat"
        name="payVat"
        onChange={togglePayVat}
        checked={company.payVat}
        className="mr-2"
      />
      <label htmlFor="payVat">Platitor TVA</label>

      {company.isVerified ? (
        <p className="text-blue-400"> Companie verificata</p>
      ) : (
        <p className="text-red-400">Companie in curs de verificare</p>
      )}

      <hr className="my-4" />
      <h4>VIEW</h4>
      <p>companie: {company.name ? company.name : "Adauga companie"}</p>
      <p>CUI: {company.cui ? company.cui : "Adauga CUI"}</p>
      <p>Reg.Com.: {company.commercialRegisterNr ? company.commercialRegisterNr : "Adauga NR"}</p>
      <p>{company.payVat ? "PayVAT:  Checked" : "PayVAT: Not checked"}</p>

      <hr className="my-4" />

      <div className="flex align-items-center justify-content-end">
        <Button
          label="Vizualizare"
          icon="pi pi-eye"
          className="p-button-outlined p-button-success w-auto mx-3"
        />
        {company.id ? (
          <Button
            label="Modifica companie"
            icon="pi pi-check"
            className="w-auto mx-3"
            onClick={updateCompany}
          />
        ) : (
          <Button
            label="Adauga companie"
            icon="pi pi-check"
            className="w-auto mx-3"
            onClick={addCompany}
          />
        )}
      </div>
    </div>
  );
};

export default EditCompany;
