import {local} from "../api/axios";

const getAll = () => {
  return local.get("/companies");
};

const get = (id) => {
  return local.get(`/companies/${id}`);
};

const create = (data) => {
  return local.post("/companies", data);
};

const update = (id, data) => {
  return local.put(`/companies/${id}`, data);
};

const remove = (id) => {
  return local.delete(`/companies/${id}`);
};

const removeAll = () => {
  return local.delete(`/companies`);
};

const findByTitle = (title) => {
  return local.get(`/companies?title=${title}`);
};

const companyService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
};

export default companyService;
