import React from "react";

import {Avatar} from "primereact/avatar";

const News = () => {
  return (
    <div>
      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="text-900 font-bold text-5xl mb-6">Postari recente</div>
        <div className="flex flex-wrap">
          <div className="w-full xl:w-6 pr-0 xl:pr-6">
            <img
              src="https://picsum.photos/500/400"
              alt="Image"
              className="w-full"
            />
            <div className="flex align-items-center mt-4">
              <img
                src="https://picsum.photos/500/400"
                className="mr-3 flex-shrink-0"
                width="28"
                height="28"
              />
              <span className="text-900 font-medium text-xl white-space-nowrap">
                Darrel Steward
              </span>
              <span className="text-500 font-medium text-xl mx-3">|</span>
              <span className="text-500 font-medium text-xl white-space-nowrap">Oct 7</span>
            </div>
            <div className="text-900 font-bold text-4xl mt-4">Circumpolar Cooperation</div>
            <p className="line-height-3 text-700 my-4">
              Purus sit amet volutpat consequat mauris nunc congue. Odio pellentesque diam volutpat
              commodo sed egestas egestas fringilla phasellus.
            </p>
            <div className="flex align-items-center">
              <span className="text-600 text-sm font-medium">15 min read</span>
              <span className="text-600 font-medium text-sm mx-3">|</span>
              <span className="bg-indigo-100 text-indigo-600 text-sm block py-1 px-2 border-round mr-3">
                Poles
              </span>
              <span className="bg-green-100 text-green-600 text-sm block py-1 px-2 border-round">
                Nature
              </span>
            </div>
          </div>
          <div className="w-full xl:w-6">
            <div className="flex flex-wrap align-items-start mb-8 mt-8 xl:mb-6 xl:mt-0">
              <div className="w-full xl:w-6 pr-0 xl:pr-4 mb-4 xl:mb-0">
                <img
                  src="https://picsum.photos/500/400"
                  alt="Image"
                  className="w-full"
                />
              </div>
              <div className="w-full xl:w-6">
                <div className="flex align-items-center">
                  <img
                    src="https://picsum.photos/500/400"
                    className="mr-3 flex-shrink-0"
                    width="28"
                    height="28"
                  />
                  <span className="text-900 font-medium text-xl white-space-nowrap">
                    Bessie Cooper
                  </span>
                  <span className="text-500 font-medium text-xl mx-3">|</span>
                  <span className="text-500 font-medium text-xl white-space-nowrap">Oct 7</span>
                </div>
                <div className="text-900 font-bold text-4xl mt-4">World Famous Fish</div>
                <p className="line-height-3 text-700 my-4">
                  Quisque sagittis purus sit amet volutpat consequat mauris.
                </p>
                <div className="flex align-items-center">
                  <span className="text-600 text-sm font-medium">12 min read</span>
                  <span className="text-600 font-medium text-sm mx-3">|</span>
                  <span className="bg-blue-100 text-blue-600 text-sm block py-1 px-2 border-round mr-3">
                    Ocean
                  </span>
                  <span className="bg-green-100 text-green-600 text-sm block py-1 px-2 border-round">
                    Nature
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap align-items-start">
              <div className="w-full xl:w-6 pr-0 xl:pr-4 mb-4 xl:mb-0">
                <img
                  src="https://picsum.photos/500/400"
                  alt="Image"
                  className="w-full"
                />
              </div>
              <div className="w-full xl:w-6">
                <div className="flex align-items-center">
                  <img
                    src="https://picsum.photos/500/400"
                    className="mr-3 flex-shrink-0"
                    width="28"
                    height="28"
                  />
                  <span className="text-900 font-medium text-xl white-space-nowrap">
                    Jenna Clark
                  </span>
                  <span className="text-500 font-medium text-xl mx-3">|</span>
                  <span className="text-500 font-medium text-xl white-space-nowrap">Oct 10</span>
                </div>
                <div className="text-900 font-bold text-4xl mt-4">Extinction and Origination</div>
                <p className="line-height-3 text-700 my-4">
                  Bibendum enim facilisis gravida neque convallis a cras.
                </p>
                <div className="flex align-items-center">
                  <span className="text-600 text-sm font-medium">10 min read</span>
                  <span className="text-600 font-medium text-sm mx-3">|</span>
                  <span className="bg-blue-100 text-blue-600 text-sm block py-1 px-2 border-round mr-3">
                    Ocean
                  </span>
                  <span className="bg-green-100 text-green-600 text-sm block py-1 px-2 border-round">
                    Nature
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
        <div className="font-bold text-6xl text-900 mb-5 text-center">Articole promovate</div>
        <div className="grid nogutter">
          <div className="col-12 lg:col-4 p-3">
            <div className="shadow-2 border-round h-full surface-card">
              <img
                src="https://picsum.photos/500/400"
                alt="blog-1"
                className="block w-full border-round-top"
              />
              <div className="p-4">
                <span className="block font-medium text-blue-600 mb-3">Crime</span>
                <div className="text-xl text-900 font-medium mb-3 line-height-3 ">
                  Fugitive flamingo spotted in Florida
                </div>
                <div className="text-sm line-height-3 mb-3 text-700">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.
                </div>
                <div className="flex">
                  <Avatar
                    image="https://picsum.photos/500/400"
                    shape="circle"
                  />
                  <div className="ml-2">
                    <div className="text-xs font-bold text-900 mb-1">Anna Lane</div>
                    <div className="text-xs flex align-items-center text-700">
                      <i className="pi pi-calendar mr-1 text-xs"></i>
                      <span>Apr 5, 2021</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-4 p-3">
            <div className="shadow-2 border-round h-full surface-card">
              <img
                src="https://picsum.photos/500/400"
                alt="blog-2"
                className="block w-full border-round-top"
              />
              <div className="p-4 flex flex-column">
                <span className="block font-medium text-blue-600 mb-3">Wildlife</span>
                <div className="text-xl text-900 font-medium mb-3 line-height-3 ">
                  Journey to the Ends of the Earth
                </div>
                <div className="text-sm line-height-3 mb-3 text-700">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.
                </div>
                <div className="flex">
                  <Avatar
                    image="https://picsum.photos/500/400"
                    shape="circle"
                  />
                  <div className="ml-2">
                    <div className="text-xs font-bold text-900 mb-1">Arlene McCoy</div>
                    <div className="text-xs flex align-items-center text-700">
                      <i className="pi pi-calendar mr-1 text-xs" />
                      <span>Apr 6, 2021</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-4 p-3">
            <div className="shadow-2 border-round h-full surface-card">
              <img
                src="https://picsum.photos/500/400"
                alt="blog-3"
                className="block w-full border-round-top"
              />
              <div className="p-4">
                <span className="block font-medium text-blue-600 mb-3">Marie</span>
                <div className="text-xl text-900 font-medium mb-3 line-height-3 ">
                  &apos;Real and imminent&apos; extinction risk
                </div>
                <div className="text-sm line-height-3 mb-3 text-700">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.
                </div>
                <div className="flex">
                  <Avatar
                    image="https://picsum.photos/500/400"
                    alt="avatar-f-3"
                    shape="circle"
                  />
                  <div className="ml-2">
                    <div className="text-xs font-bold text-900 mb-1">Diane Miles</div>
                    <div className="text-xs flex align-items-center text-700">
                      <i className="pi pi-calendar mr-1 text-xs" />
                      <span>Apr 9, 2021</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
