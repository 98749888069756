import React from "react";
import {Button} from "primereact/button";
import useAdvert from "../../hooks/useAdvert";

const AdvertsListItemCard = ({advert}) => {
  const {deleteAdvert, advertEditHandler} = useAdvert();
  console.log(advert);
  return (
    <div className="col-12 md:col-6 xl:col-3 p-3">
      <div
        className="surface-card shadow-2 border-rounded p-3"
        style={{borderRadius: "6px"}}>
        <div className="flex justify-content-between align-items-start">
          <div>
            <div className="text-xl font-medium text-900 mb-2">{advert.name}</div>
            <p className="mt-0 mb-3 text-600">{advert.descriptionShort}</p>
          </div>
          <Button
            icon="pi pi-pencil"
            className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
        </div>
        <div className="flex justify-content-between pt-3">
          <Button
            label="432"
            icon="pi pi-eye"
            className="p-button-text p-button-secondary w-6 mr-2"
          />
          <Button
            label="65"
            icon="pi pi-heart"
            className="p-button-text p-button-secondary w-6 ml-2"
          />
        </div>
        <img
          src={advert.img}
          className="mb-3 w-full"
        />
        <ul className="list-none m-0 p-0">
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Creare</span>
            <span className="text-900 font-medium text-sm">{advert.created}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Redactare</span>
            <span className="text-900 font-medium text-sm">{advert.edited}</span>
          </li>
        </ul>
        <div className="flex justify-content-between pt-3">
          <Button
            onClick={() => deleteAdvert(advert.id)}
            // label="Sterge"
            icon="pi pi-trash"
            className="p-button-text p-button-secondary bg-red-100 w-4 ml-2"
          />
          <Button
            onClick={() => advertEditHandler(advert.id)}
            // label="Detalii"
            icon="pi pi-database"
            className="p-button-text p-button-secondary bg-gray-100 w-4 mr-2"
          />
        </div>
      </div>
    </div>
  );
};
export default AdvertsListItemCard;
