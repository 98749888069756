import React from "react";
import useAuth from "../../hooks/useAuth";

const Profile = () => {
  const {auth, logoutHandler} = useAuth();

  return (
    <div>
      <h1> Welcome {auth.user}</h1>
      <button onClick={logoutHandler}>Logout</button>
    </div>
  );
};
export default Profile;
