import React from "react";
import useAuth from "../../hooks/useAuth";

const Title = (props) => {
  const {auth} = useAuth();

  return (
    <section
      className="border-blue-600 mb-3 lg:mb-0"
      style={{borderLeft: "8px solid"}}>
      <div className="text-500 ml-3 mr-0 md:mr-3">{auth.user}</div>
      <div className="text-3xl font-medium text-900 ml-3 mb-3">{props.title}</div>
    </section>
  );
};
export default Title;
