import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Carousel} from "primereact/carousel";
import React from "react";
import {Link} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import data from "../assets/data/db.json";

const Login = () => {
  const {
    userEmail,
    password,
    loginHandler,
    userRef,
    errMsg,
    errRef,
    emailChangeHandler,
    passwordChangeHandler,
    validateEmailHandler,
    validatePasswordHandler,
  } = useAuth();

  return (
    <div className="">
      <section className="flex flex-wrap shadow-2">
        <div className="w-full lg:w-6 px-0 py-4 lg:p-7 bg-blue-50">
          <Carousel
            value={data.features}
            itemTemplate={(feature) => (
              <div className="text-center mb-8">
                <img
                  src={feature.image}
                  alt="s-ss"
                  className="mb-6 w-6"
                />
                <div className="mx-auto font-medium text-xl mb-4 text-blue-900">
                  {feature.title}
                </div>
                <p className="m-0 text-blue-700 line-height-3">{feature.text}</p>
              </div>
            )}
          />
        </div>
        <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
          <p
            ref={errRef}
            className={errMsg ? "bg-pink-100" : "hidden"}
            aria-live="assertive">
            {errMsg}
          </p>
          <div className="flex align-items-center justify-content-between mb-7">
            <span className="text-2xl font-medium text-900">Intra in contul tau</span>
          </div>
          <label
            htmlFor="userEmail"
            className="block text-900 font-medium mb-2">
            User Email:
          </label>
          <InputText
            type="text"
            id="userEmail"
            ref={userRef}
            autoComplete="off"
            onChange={emailChangeHandler}
            onBlur={validateEmailHandler}
            value={userEmail}
            required
            className="w-full mb-3 p-3"
          />
          <label
            htmlFor="password"
            className="block text-900 font-medium mb-2">
            Password
          </label>
          <InputText
            type="password"
            id="password"
            ref={userRef}
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
            value={password}
            required
            className="w-full mb-3 p-3"
          />
          <div className="flex align-items-center justify-content-between mb-6">
            <Link
              to="/register"
              tabIndex="0"
              className="font-medium text-blue-500 hover:text-blue-700 \n
              cursor-pointer transition-colors transition-duration-150">
              Inregistrare
            </Link>
            <Link
              to="/reset-password"
              className="font-medium text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150">
              Parola uitata?
            </Link>
          </div>

          <div className="col-12">
            <Button
              label="Login"
              onClick={loginHandler}
              className="w-auto mt-3"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default Login;
