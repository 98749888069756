import React from "react";
import Logo from "../logo.png";
import {NavLink} from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Header = () => {
  const {auth, logoutHandler} = useAuth();

  return (
    <div>
      <div
        className="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static"
        style={{minHeight: "80px"}}>
        <NavLink to="/auth/dashboard">
          <img
            src={Logo}
            alt="bastion-700"
            height={40}
            className="mr-0 lg:mr-6"
          />
        </NavLink>
        <div className="align-items-center flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
          <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
            <li>
              <NavLink
                to="/siscore"
                style={{textDecoration: "none"}}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                <i className="pi pi-chart-line mr-2" />
                <span>Siscore</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                style={{textDecoration: "none"}}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                <i className="pi pi-info-circle mr-2" />
                <span>Despre noi</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/news"
                style={{textDecoration: "none"}}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                <i className="pi pi-tags mr-2" />
                <span>Noutati</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/terms"
                style={{textDecoration: "none"}}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                <i className="pi pi-shield mr-2" />
                <span>Termeni si conditii</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faq"
                style={{textDecoration: "none"}}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                <i className="pi pi-question-circle mr-2" />
                <span>Intrebari</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                style={{textDecoration: "none"}}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                <i className="pi pi-inbox mr-2" />
                <span>Contact</span>
              </NavLink>
            </li>
            <li className="border-top-1 surface-border lg:border-top-none ml-5">
              {!auth.accessToken && (
                <NavLink
                  to="auth/profile"
                  style={{textDecoration: "none"}}
                  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                  <i className="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge" />
                  <span className="block lg:hidden font-medium">Autentificare</span>
                </NavLink>
              )}
              {auth.accessToken && <button onClick={logoutHandler}>Logout</button>}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
