import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import CompaniesList from "./CompaniesList";
import Title from "../../../components/ui/Title";
import useCompany from "../../../hooks/useCompany";
import CompanyItem from "./CompanyItem";
import useCommonContext from "../../../hooks/useCommonContext";

const Companies = () => {
  const {companies, company, newCompanyHandler} = useCompany();
  const {showDetails} = useCommonContext();

  let content = <CompaniesList />;

  if (!companies) {
    content = (
      <div className="surface-card border-round shadow-2 p-4">
        <div className="text-900 font-medium mb-3 text-xl">Nu aveti companii adaugate</div>
        <p className="mt-0 mb-4 p-0 line-height-3">
          Pentru adaugare companie, introduceti CUI in campul urmator. Toate datele necesare se vor
          colecta de catre platforma Siscone in mod automat
        </p>
        <div className="field">
          <label
            htmlFor="cui"
            className="block text-900 font-medium mb-2">
            CUI
          </label>
          <InputText
            id="cui"
            className="py-3 px-2 text-lg"
          />
        </div>
        <Link
          to="/auth/new-company"
          className="p-ripple flex align-items-center cursor-pointer"
          style={{textDecoration: "none"}}>
          <Button label="Adaugare companie" />
        </Link>
      </div>
    );
  }

  return (
    <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
      <div className="flex justify-content-between">
        <Title title="Companiile inregistrate" />
        <div
          className="flex align-items-center justify-content-center bg-blue-100 border-round"
          style={{width: "2.5rem", height: "2.5rem"}}>
          <Link
            to="/auth/new-company"
            onClick={newCompanyHandler}
            className="p-ripple flex align-items-center cursor-pointer ">
            <i className="pi pi-plus-circle text-blue-500 text-xl" />
          </Link>
        </div>
      </div>
      <div className="w-full flex justify-content-center align-items-center">{content}</div>
      {showDetails && (
        <CompanyItem
          company={company}
          id={company.id}
        />
      )}
    </div>
  );
};

export default Companies;
