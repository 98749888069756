import {createContext, useEffect, useRef, useState} from "react";
import projectData from "../services/projectService";
import useCommonContext from "../hooks/useCommonContext";
import {useNavigate, useParams} from "react-router-dom";

const ProjectContext = createContext({});

export const ProjectProvider = ({children}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {setFetchErrMsg, setIsLoading, setErrMsg, errRef, setActiveTab, setShowDetails} =
    useCommonContext();

  const projectInitial = {
    value: "",
    startEnd: "",
    city: "",
    stage: "",
    roleIn: "",
    contactPerson: "",
    contactPhone: "",
    contactEmail: "",
    details: "",
    name: "",
    brief: "",
    img: "https://loremflickr.com/320/240/space?random=2",
    views: 0,
    likes: 0,
    type: [],
    created: "",
    edited: "",
    clientCompany: {
      cui: "",
      role: "",
      contactPerson: "",
      name: "",
      commercialRegister: "",
      note: "",
      city: "",
      contactPhone: "",
      contactEmail: "",
    },
  };
  const [project, setProject] = useState(projectInitial);
  const [projects, setProjects] = useState([]);

  const newProjectHandler = () => {
    setProject(projectInitial);
    navigate("/auth/new-project");
  };
  const projectEditHandler = (id) => {
    setShowDetails(true);
    fetchProject(id);
  };

  // to handle inputdata for nested object, like project.clientCompany.role
  // const inputChangeHandlerChildObj = (event) => {
  //   const {name, value} = event.target;
  //   setProject((project) => {
  //     return {
  //       ...project,
  //       item: {
  //         ...project.item,
  //         [name]: value,
  //       },
  //     };
  //   });
  // };

  const inputChangeHandlerClientCompany = (event) => {
    const {name, value} = event.target;
    setProject((project) => {
      return {
        ...project,
        clientCompany: {
          ...project.clientCompany,
          [name]: value,
        },
      };
    });
  };

  const inputChangeHandler = (event) => {
    const {name, value} = event.target;
    console.log(name, value);
    setProject({...project, [name]: value});
  };

  const fetchProject = async (id) => {
    try {
      const response = await projectData.get(id);
      setProject(response.data);
      setFetchErrMsg(null);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        setFetchErrMsg(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await projectData.getAll();
      setProjects(response.data);
      setFetchErrMsg(null);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        setFetchErrMsg(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("/projects/edit")) {
      let params = window.location.pathname.split("/");
      let id = params.slice(-1);
      fetchProject(+id);
      console.log("iau proiectul din baza de date");
    }
    setTimeout(() => {
      fetchProjects();
    }, 500);
  }, []);

  const addProject = async (e) => {
    e.preventDefault();
    try {
      await projectData.create(project);
      fetchProjects();
      setShowDetails(true);
      setActiveTab(0);
      navigate("/auth/projects");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing company");
      } else {
        setErrMsg("Fetch failed");
      }
      errRef.current.focus();
    }
  };

  const updateProject = async (e) => {
    e.preventDefault();
    try {
      await projectData.update(project.id, project);
      setActiveTab(0);
      fetchProjects();
      navigate("/auth/projects");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing company");
      } else {
        setErrMsg("Fetch failed");
      }
      errRef.current.focus();
    }
  };

  const deleteProject = async (id) => {
    try {
      await projectData.remove(id);
      setShowDetails(false);
      fetchProjects();
    } catch (err) {
      console.log(`Error:${err.message}`);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projectInitial,
        project,
        setProject,
        projects,
        setProjects,
        newProjectHandler,
        inputChangeHandler,
        inputChangeHandlerClientCompany,
        projectEditHandler,
        addProject,
        deleteProject,
        updateProject,
      }}>
      {children}
    </ProjectContext.Provider>
  );
};
export default ProjectContext;
