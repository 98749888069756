import React, {useState} from "react";
import {Link} from "react-router-dom";
import CompaniesListItemCard from "../../../components/company/CompaniesListItemCard";
import useCompany from "../../../hooks/useCompany";
import useCommonContext from "../../../hooks/useCommonContext";

const CompaniesList = () => {
  const {companies} = useCompany();
  const {fetchErrMsg, isLoading} = useCommonContext();
  return (
    <div className="w-full flex justify-content-center align-items-center">
      <div className="w-full surface-section ">
        <section className="surface-ground flex flex-column px-4 py-5 md:px-6">
          <div className="grid">
            {isLoading && <p>Se încarcă companiile...</p>}
            {fetchErrMsg && <p className="text-red-400">{`Error: ${fetchErrMsg}`}</p>}
            {!fetchErrMsg &&
              !isLoading &&
              companies.map((company) => (
                <CompaniesListItemCard
                  key={company.id}
                  company={company}
                />
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default CompaniesList;
