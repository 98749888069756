import React from "react";
import {Tag} from "primereact/tag";
import {InputText} from "primereact/inputtext";
import useAdvert from "../../hooks/useAdvert";

function TagsInput() {
  const {handleKeyDownTag, removeTag, advert} = useAdvert();

  console.log(advert.tags);
  return (
    <div className="field">
      <label
        htmlFor="price"
        className="block text-900 font-medium">
        Cuvinte cheie
      </label>
      <InputText
        onKeyDown={handleKeyDownTag}
        type="text"
        className="py-3 px-2 text-lg mb-2"
      />
      <div className="flex flex-wrap justify-content-center gap-2">
        {advert.tags.map((tag, index) => (
          <Tag
            className="py-2 mx-1"
            severity="info"
            value={tag}
            key={index}>
            <span
              className="px-2"
              onClick={() => removeTag(index)}>
              &times;
            </span>
          </Tag>
        ))}
      </div>
    </div>
  );
}

export default TagsInput;
