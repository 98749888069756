import axios from "axios";

axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["withCredentials"] = true;
axios.defaults.headers["Access-Control-Allow-Credentials"] = true;
axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;
console.log("AXIOS:" + axios.defaults.headers["Authorization"]);

let baseUrl = process.env.API_URL ? process.env.API_URL : "https://api.siscone.ro/api";

const http = axios.create({
  baseURL: baseUrl,
});

const local = axios.create({
  baseURL: "http://localhost:3030",
});

export {http, local};
