import React from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import useAdvert from "../../hooks/useAdvert";

function EditService() {
  const {advert, inputChangeHandlerChild, detailCheckHandler, selectedDetails} = useAdvert();

  return (
    <div className="py-5">
      <div className="flex flex-column md:flex-row flex-between w-full p-3">
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="category"
              className="block text-900 font-medium mb-2">
              Subcategorie ocupatie
            </label>
            <InputText
              id="category"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "service")}
              value={advert.service.subcategory}
              name="category"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
        <div className="md:w-6 p-fluid pr-0 md:pr-6">
          <div className="field">
            <label
              htmlFor="price"
              className="block text-900 font-medium mb-2">
              Pret
            </label>
            <InputText
              id="price"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "service")}
              value={advert.service.price}
              name="price"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
          <div className="field">
            <label
              htmlFor="term"
              className="block text-900 font-medium mb-2">
              Termen
            </label>
            <InputText
              id="term"
              type="text"
              autoComplete="off"
              onChange={(e) => inputChangeHandlerChild(e, "service")}
              value={advert.service.term}
              name="term"
              required
              className="py-3 px-2 text-lg"
            />
          </div>
        </div>
      </div>
      {advert.service.img.length > 0 ? (
        <div className="flex flex-column">
          <div className="flex align-items-center mb-4">
            <span className="text-xl font-medium text-900 mr-5">Foto Servicii</span>
            <Button
              icon="pi pi-upload"
              className="p-button-rounded p-button-outlined"
            />
          </div>
          <div className="grid">
            {advert.service.img.map((item, index) => {
              return (
                <div
                  className="col-12 md:col-6 xl:col-3 p-3"
                  key={index}>
                  <div
                    className="surface-card shadow-2 border-rounded p-3"
                    style={{borderRadius: "6px"}}>
                    <img
                      src={item.path}
                      className="mb-3 w-full"
                    />
                    <div className="flex justify-content-between align-items-start">
                      <div>
                        <div className="text-xl font-medium text-900 mb-2">{item.title}</div>
                        <p className="mt-0 mb-3 text-600">{item.text}</p>
                      </div>
                      <Button
                        icon="pi pi-download"
                        className="p-button-rounded p-button-text"
                      />
                      <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-text"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className=" mb-3 lg:mb-0 flex md:align-items-center md:justify-content-center flex-column  pb-4">
          <div className="text-500 mb-3">Adaugati fotografie pentru noul serviciu</div>
          <Button
            label="Adauga foto"
            className="p-button-outlined "
          />
        </div>
      )}

      <div className="flex align-items-center">
        <span className="w-2 text-xl font-medium text-900 mr-5 ">Detalii</span>
        <div className="grid mt-4 ">
          {advert.service.details.map((detail) => {
            return (
              <div
                className="field-checkbox mr-5"
                key={detail.key}>
                <Checkbox
                  inputId={detail.key}
                  name="detail"
                  value={detail}
                  onChange={detailCheckHandler}
                  checked={selectedDetails.some((item) => item.key === detail.key)}
                />
                <label htmlFor={detail.key}>{detail.name}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EditService;
