import React from "react";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import useAdvert from "../../../hooks/useAdvert";
import Title from "../../../components/ui/Title";
import AdvertsListCategories from "./AdvertsListCategories";
import useCommonContext from "../../../hooks/useCommonContext";
import AdvertsList from "./AdvertsList";

const Adverts = () => {
  const {advert, adverts, addAdvert, newAdvertHandler} = useAdvert();
  const {showDetails} = useCommonContext();

  let content = <AdvertsListCategories />;

  if (!adverts) {
    console.log("adverts.jsx", adverts.length, adverts);
    content = (
      <div className="surface-card border-round shadow-2 p-4">
        <div className="text-900 font-medium mb-3 text-xl">Nu aveti anunturi adaugate</div>

        <Link
          to="/auth/new-advert"
          className="p-ripple flex align-items-center cursor-pointer"
          style={{textDecoration: "none"}}>
          <Button label="Adaugare anunț" />
        </Link>
      </div>
    );
  }

  return (
    <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
      <div className="flex justify-content-between">
        <Title title="Anunțuri publicate" />
        <div
          className="flex align-items-center justify-content-center bg-blue-100 border-round"
          style={{width: "2.5rem", height: "2.5rem"}}>
          <Link
            to="/auth/new-advert"
            className="p-ripple flex align-items-center cursor-pointer ">
            <i className="pi pi-plus-circle text-blue-500 text-xl" />
          </Link>
        </div>
      </div>
      <div className="w-full flex justify-content-center align-items-center">{content}</div>
      {showDetails && (
        <AdvertsList
        // advert={advert}
        // id={advert.type}
        />
      )}
    </div>

    // <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
    //   <div
    //     className="border-blue-600 lg:mb-0 flex flex-column"
    //     style={{borderLeft: "8px solid"}}>
    //     <div className="text-500 ml-3 mr-0 md:mr-3">SC Constructorul SRL</div>
    //     <div className="text-3xl font-medium text-900 ml-3">Anunturi publicate</div>
    //   </div>
    //   <div className="surface-ground flex flex-column my-3 px-4 py-5 md:px-6">
    //     <div className="grid">
    //       <div className="col-12 md:col-6 lg:col-3">
    //         <div className="surface-card shadow-2 p-3 border-round">
    //           <div className="flex justify-content-between mb-3">
    //             <div>
    //               <span className="block text-green-500 font-medium mb-3">Produse</span>
    //               <div className="text-900 font-medium text-xl">
    //                 <span className="text-500">Actuale:</span> 2
    //               </div>
    //             </div>
    //             <div
    //               className="flex align-items-center justify-content-center bg-blue-100 border-round"
    //               style={{width: "2.5rem", height: "2.5rem"}}>
    //               <Link
    //                 to="/auth/new-advert/product"
    //                 className="p-ripple flex align-items-center cursor-pointer ">
    //                 <i className="pi pi-plus-circle text-blue-500 text-xl" />
    //               </Link>
    //             </div>
    //           </div>
    //           <span className="text-gray-500 font-medium">
    //             {" "}
    //             <span className="text-500">Arhivate</span> 12{" "}
    //           </span>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 lg:col-3">
    //         <div className="surface-card shadow-2 p-3 border-round">
    //           <div className="flex justify-content-between mb-3">
    //             <div>
    //               <span className="block text-green-500 font-medium mb-3">Servicii</span>
    //               <div className="text-900 font-medium text-xl">
    //                 <span className="text-500">Actuale:</span> 3
    //               </div>
    //             </div>
    //             <div
    //               className="flex align-items-center justify-content-center bg-blue-100 border-round"
    //               style={{width: "2.5rem", height: "2.5rem"}}>
    //               <Link
    //                 to="/auth/new-advert/service"
    //                 className="p-ripple flex align-items-center cursor-pointer ">
    //                 <i className="pi pi-plus-circle text-blue-500 text-xl" />
    //               </Link>
    //             </div>
    //           </div>
    //           <span className="text-gray-500 font-medium">
    //             {" "}
    //             <span className="text-500">Arhivate</span> 7{" "}
    //           </span>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 lg:col-3">
    //         <div className="surface-card shadow-2 p-3 border-round">
    //           <div className="flex justify-content-between mb-3">
    //             <div>
    //               <span className="block text-green-500 font-medium mb-3">Angajari</span>
    //               <div className="text-900 font-medium text-xl">
    //                 <span className="text-500">Actuale:</span> Nu aveti anunturi
    //               </div>
    //             </div>
    //             <div
    //               className="flex align-items-center justify-content-center bg-blue-100 border-round"
    //               style={{width: "2.5rem", height: "2.5rem"}}>
    //               <Link
    //                 to="/auth/new-advert/job"
    //                 className="p-ripple flex align-items-center cursor-pointer ">
    //                 <i className="pi pi-plus-circle text-blue-500 text-xl" />
    //               </Link>
    //             </div>
    //           </div>
    //           <span className="text-gray-500 font-medium">
    //             {" "}
    //             <span className="text-500">Arhivate</span> 5{" "}
    //           </span>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 lg:col-3">
    //         <div className="surface-card shadow-2 p-3 border-round">
    //           <div className="flex justify-content-between mb-3">
    //             <div>
    //               <span className="block text-green-500 font-medium mb-3">Licitatii</span>
    //               <div className="text-900 font-medium text-xl">
    //                 <span className="text-500">Actuale:</span> 1
    //               </div>
    //             </div>
    //             <div
    //               className="flex align-items-center justify-content-center bg-blue-100 border-round"
    //               style={{width: "2.5rem", height: "2.5rem"}}>
    //               <Link
    //                 to="/auth/new-advert/tender"
    //                 className="p-ripple flex align-items-center cursor-pointer ">
    //                 <i className="pi pi-plus-circle text-blue-500 text-xl" />
    //               </Link>
    //             </div>
    //           </div>
    //           <span className="text-gray-500 font-medium">
    //             {" "}
    //             <span className="text-500">Arhivate</span> 5{" "}
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //
    //   <div className="surface-ground my-3 px-4 py-5 md:px-6">
    //     <div className="grid">
    //       <div className="col-12 md:col-6 xl:col-3 p-3">
    //         <div
    //           className="surface-card shadow-2 border-rounded p-3"
    //           style={{borderRadius: "6px"}}>
    //           <div className="flex justify-content-between align-items-start">
    //             <div>
    //               <div className="text-xl font-medium text-900 mb-2">Mocheta</div>
    //               <p className="mt-0 mb-3 text-600">Scurta descriereȘ 10-15 cuvinte</p>
    //             </div>
    //             <Button
    //               icon="pi pi-pencil"
    //               className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
    //           </div>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="432"
    //               icon="pi pi-eye"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="65"
    //               icon="pi pi-heart"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //           <img
    //             src="https://picsum.photos/500/400"
    //             className="mb-3 w-full"
    //           />
    //           <ul className="list-none m-0 p-0">
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Creare</span>
    //               <span className="text-900 font-medium text-sm">21.11.0222</span>
    //             </li>
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Redactare</span>
    //               <span className="text-900 font-medium text-sm">25.11.2022</span>
    //             </li>
    //           </ul>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="Arhiveaza"
    //               icon="pi pi-database"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="Sterge"
    //               icon="pi pi-delete"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 xl:col-3 p-3">
    //         <div
    //           className="surface-card shadow-2 border-rounded p-3"
    //           style={{borderRadius: "6px"}}>
    //           <div className="flex justify-content-between align-items-start">
    //             <div className="w-9">
    //               <div className="text-xl font-medium text-900 mb-2">Linoleum</div>
    //               <p className="mt-0 mb-3 text-600">
    //                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in,
    //                 laboriosam rerum sed sequi!
    //               </p>
    //             </div>
    //             <Button
    //               icon="pi pi-pencil"
    //               className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
    //           </div>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="201"
    //               icon="pi pi-eye"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="40"
    //               icon="pi pi-heart"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //           <img
    //             src="https://picsum.photos/500/400"
    //             className="mb-3 w-full"
    //           />
    //           <ul className="list-none m-0 p-0">
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Creare</span>
    //               <span className="text-900 font-medium text-sm">22.11.0222</span>
    //             </li>
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Redactare</span>
    //               <span className="text-900 font-medium text-sm">25.11.2022</span>
    //             </li>
    //           </ul>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="Arhiveaza"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="Sterge"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 xl:col-3 p-3">
    //         <div
    //           className="surface-card shadow-2 border-rounded p-3"
    //           style={{borderRadius: "6px"}}>
    //           <div className="flex justify-content-between align-items-start">
    //             <div className="w-9">
    //               <div className="text-xl font-medium text-900 mb-2">Parchet</div>
    //               <p className="mt-0 mb-3 text-600">
    //                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in,
    //                 laboriosam rerum sed sequi!
    //               </p>
    //             </div>
    //             <Button
    //               icon="pi pi-pencil"
    //               className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
    //           </div>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="201"
    //               icon="pi pi-eye"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="40"
    //               icon="pi pi-heart"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //           <img
    //             src="https://picsum.photos/500/400"
    //             className="mb-3 w-full"
    //           />
    //           <ul className="list-none m-0 p-0">
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Creare</span>
    //               <span className="text-900 font-medium text-sm">02.12.0222</span>
    //             </li>
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Redactare</span>
    //               <span className="text-900 font-medium text-sm">-</span>
    //             </li>
    //           </ul>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="Arhiveaza"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="Sterge"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 xl:col-3 p-3">
    //         <div
    //           className="surface-card shadow-2 border-rounded p-3"
    //           style={{borderRadius: "6px"}}>
    //           <div className="flex justify-content-between align-items-start">
    //             <div className="w-9">
    //               <div className="text-xl font-medium text-900 mb-2">Covor</div>
    //               <p className="mt-0 mb-3 text-600">
    //                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in,
    //                 laboriosam rerum sed sequi!
    //               </p>
    //             </div>
    //             <Button
    //               icon="pi pi-pencil"
    //               className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
    //           </div>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="201"
    //               icon="pi pi-eye"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="40"
    //               icon="pi pi-heart"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //           <img
    //             src="https://picsum.photos/500/400"
    //             className="mb-3 w-full"
    //           />
    //           <ul className="list-none m-0 p-0">
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Creare</span>
    //               <span className="text-900 font-medium text-sm">05.12.2022</span>
    //             </li>
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Redactare</span>
    //               <span className="text-900 font-medium text-sm">15.12.2022</span>
    //             </li>
    //           </ul>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="Arhiveaza"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="Sterge"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 xl:col-3 p-3">
    //         <div
    //           className="surface-card shadow-2 border-rounded p-3"
    //           style={{borderRadius: "6px"}}>
    //           <div className="flex justify-content-between align-items-start">
    //             <div className="w-9">
    //               <div className="text-xl font-medium text-900 mb-2">Mocheta</div>
    //               <p className="mt-0 mb-3 text-600">
    //                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in,
    //                 laboriosam rerum sed sequi!
    //               </p>
    //             </div>
    //             <Button
    //               icon="pi pi-pencil"
    //               className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
    //           </div>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="201"
    //               icon="pi pi-eye"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="40"
    //               icon="pi pi-heart"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //           <img
    //             src="https://picsum.photos/500/400"
    //             className="mb-3 w-full"
    //           />
    //           <ul className="list-none m-0 p-0">
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Creare</span>
    //               <span className="text-900 font-medium text-sm">22.11.0222</span>
    //             </li>
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Redactare</span>
    //               <span className="text-900 font-medium text-sm">25.11.2022</span>
    //             </li>
    //           </ul>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="Arhiveaza"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="Sterge"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 xl:col-3 p-3">
    //         <div
    //           className="surface-card shadow-2 border-rounded p-3"
    //           style={{borderRadius: "6px"}}>
    //           <div className="flex justify-content-between align-items-start">
    //             <div className="w-9">
    //               <div className="text-xl font-medium text-900 mb-2">Parchet</div>
    //               <p className="mt-0 mb-3 text-600">
    //                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in,
    //                 laboriosam rerum sed sequi!
    //               </p>
    //             </div>
    //             <Button
    //               icon="pi pi-pencil"
    //               className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
    //           </div>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="201"
    //               icon="pi pi-eye"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="40"
    //               icon="pi pi-heart"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //           <img
    //             src="https://picsum.photos/500/400"
    //             className="mb-3 w-full"
    //           />
    //           <ul className="list-none m-0 p-0">
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Creare</span>
    //               <span className="text-900 font-medium text-sm">22.11.0222</span>
    //             </li>
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Redactare</span>
    //               <span className="text-900 font-medium text-sm">25.11.2022</span>
    //             </li>
    //           </ul>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="Arhiveaza"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="Sterge"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-12 md:col-6 xl:col-3 p-3">
    //         <div
    //           className="surface-card shadow-2 border-rounded p-3"
    //           style={{borderRadius: "6px"}}>
    //           <div className="flex justify-content-between align-items-start">
    //             <div className="w-9">
    //               <div className="text-xl font-medium text-900 mb-2">Linoleum</div>
    //               <p className="mt-0 mb-3 text-600">
    //                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dolore in,
    //                 laboriosam rerum sed sequi!
    //               </p>
    //             </div>
    //             <Button
    //               icon="pi pi-pencil"
    //               className="p-button  p-button-text p-button-icon-only bg-blue-100 border-round"></Button>
    //           </div>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="201"
    //               icon="pi pi-eye"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="40"
    //               icon="pi pi-heart"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //           <img
    //             src="https://picsum.photos/500/400"
    //             className="mb-3 w-full"
    //           />
    //           <ul className="list-none m-0 p-0">
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Creare</span>
    //               <span className="text-900 font-medium text-sm">22.11.0222</span>
    //             </li>
    //             <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
    //               <span className="text-600 font-medium text-sm">Redactare</span>
    //               <span className="text-900 font-medium text-sm">25.11.2022</span>
    //             </li>
    //           </ul>
    //           <div className="flex justify-content-between pt-3">
    //             <Button
    //               label="Arhiveaza"
    //               className="p-button-text p-button-secondary w-6 mr-2"
    //             />
    //             <Button
    //               label="Sterge"
    //               className="p-button-text p-button-secondary w-6 ml-2"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Adverts;
