import React from "react";
import {Tag} from "primereact/tag";

const Terms = () => {
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="text-600 text-sm mb-3">
        <span>Published </span>
        <span className="font-bold">April 6, 2021</span>
        <span> by </span>
        <span className="font-bold">Claire Williams</span>
      </div>
      <div
        className="border-blue-600 font-medium text-2xl p-3 mb-3 text-900"
        style={{borderLeft: "8px solid"}}>
        Content Section Title
      </div>
      <div className="line-height-3 text-xl text-700 mb-5">
        Metus aliquam eleifend mi in nulla posuere. In vitae turpis massa sed elementum tempus
        egestas sed. Dui ut ornare lectus sit. Massa ultricies mi quis hendrerit.
      </div>
      <div className="mb-5">
        <Tag
          value="New"
          className="mr-2"
        />
        <Tag
          value="Technology"
          severity="success"
          className="mr-2"
        />
        <Tag
          value="Education"
          severity="warning"
        />
      </div>
      <div className="line-height-3 text-lg text-700 mb-4">
        Nisi est sit amet facilisis. Ac odio tempor orci dapibus ultrices in iaculis nunc sed.
        Pellentesque pulvinar pellentesque habitant morbi tristique senectus. Nullam vehicula ipsum
        a arcu cursus vitae congue. Leo urna molestie at elementum eu facilisis. Fusce id velit ut
        tortor. Purus non enim praesent elementum facilisis leo vel fringilla est. Semper risus in
        hendrerit gravida rutrum quisque non tellus orci. Neque convallis a cras semper. Vitae
        elementum curabitur vitae nunc sed. Ornare massa eget egestas purus viverra accumsan in. Leo
        integer malesuada nunc vel risus commodo viverra maecenas accumsan. Sit amet risus nullam
        eget. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at.
      </div>
      <blockquote className="line-height-3 text-xl text-600 mb-4 py-0 mx-0 px-4">
        “In short, GPU.js is a JavaScript acceleration library that can be used for general-purpose
        computations on GPUs using JavaScript. It supports browsers, Node.js and TypeScript.”
      </blockquote>
      <div className="line-height-3 text-lg text-700 mb-4">
        Convallis tellus id interdum velit laoreet id donec ultrices. Lacus sed viverra tellus in
        hac habitasse platea dictumst. Ultricies tristique nulla aliquet enim. Nunc scelerisque
        viverra mauris in aliquam sem fringilla. Laculis urna id volutpat lacus laoreet.
      </div>
      <ul className="mb-4 text-lg ml-6 p-0">
        <li className="mb-3">Nunc sed velit dignissim sodales ut.</li>
        <li className="mb-3">Lorem mollis aliquam ut porttitor. </li>
        <li>Urna nec tincidunt praesent semper feugiat nibh sed.</li>
      </ul>
      <div className="text-center mb-4">
        <img
          src="/assets/images/blocks/content/content-1.jpg"
          alt="content-1"
          className="w-full border-round"
        />
        <span className="block text-700 line-height-3 mt-2">
          Id porta nibh venenatis cras sed felis eget velit.
        </span>
      </div>

      <div className="text-2xl text-900 mb-4">Massa vitae tortor condimentum lacinia</div>
      <div className="line-height-3 text-lg text-700 mb-4">
        Lacus viverra vitae congue eu consequat ac felis donec et. A lacus vestibulum sed arcu non.
        Mauris vitae ultricies leo integer malesuada nunc vel. Dolor sit amet consectetur adipiscing
        elit ut aliquam purus. Ac tincidunt vitae semper quis lectus nulla at volutpat. Enim
        lobortis scelerisque fermentum dui faucibus in ornare. In mollis nunc sed id semper. Vitae
        ultricies leo integer malesuada nunc vel risus commodo.
      </div>
    </div>
  );
};

export default Terms;
