import React, {Component} from "react";
import {PublicRoutes, AuthRoutes} from "./routes";
import Layout from "./Layout/Layout";
import {Routes, Route} from "react-router-dom";
import RequireAuth from "./pages/RequireAuth";
import MultiProvider from "./context/multiProvider";
import {CompanyProvider} from "./context/companyContext";
import {AuthProvider} from "./context/authContext";
import {ProjectProvider} from "./context/projectContext";
import {CommonProvider} from "./context/commonContext";
import {AdvertProvider} from "./context/advertContext";

const App = () => {
  return (
    <MultiProvider
      providers={[
        <AuthProvider key={1} />,
        <CommonProvider key={2} />,
        <CompanyProvider key={3} />,
        <ProjectProvider key={4} />,
        <AdvertProvider key={5} />,
      ]}>
      <Layout>
        <Routes>
          {PublicRoutes}
          <Route element={<RequireAuth />}>{AuthRoutes}</Route>
        </Routes>
      </Layout>
    </MultiProvider>
  );
};

export default App;
