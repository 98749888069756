import React from "react";
import useCompany from "../../hooks/useCompany";

const CompanyItemProfileFinance = () => {
  const {company} = useCompany();

  return (
    <div className="col-12 md:col-6 p-3">
      <div
        className="surface-card shadow-2 border-rounded p-3"
        style={{borderRadius: "6px"}}>
        <div className="text-xl font-medium text-900 mb-2">Profil Financiar 2021</div>
        <ul className="list-none m-0 p-0">
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Cifra de afaceri</span>
            <span className="text-900 font-medium text-sm">
              {company.financial.fiscalValue} RON{" "}
            </span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrow-up text-green-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.fiscalValue} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm ">Profit</span>
            <span className="text-900 font-medium text-sm ">{company.financial.profit} RON </span>
            <span className="text-900 font-medium text-sm ">
              <i className="pi pi-arrow-up text-green-500 " />
            </span>
            <span className="text-900 font-medium text-sm ">
              {company.siscoreOfficial.profit} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Vechime societate</span>
            <span className="text-900 font-medium text-sm">
              {company.financial.companyAge} ani{" "}
            </span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrow-up text-green-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.companyAge} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Obligatii restante stat</span>
            <span className="text-900 font-medium text-sm">
              {company.financial.outstandingObligations}{" "}
            </span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrows-h text-blue-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.outstandingObligations} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Contracte &gt; 100.000 RON</span>
            <span className="text-900 font-medium text-sm">{company.financial.bigDeals} </span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrow-up text-green-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.bigDeals} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Active circulante</span>
            <span className="text-900 font-medium text-sm">
              {company.financial.currentAssets} RON
            </span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrow-down text-red-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.currentAssets} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Active imobilizate</span>
            <span className="text-900 font-medium text-sm">
              {company.financial.fixedAssets} RON
            </span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrow-up text-green-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.fixedAssets} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Datorii</span>
            <span className="text-900 font-medium text-sm">
              {company.financial.liabilities} RON
            </span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrow-up text-green-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.liabilities} SiScore
            </span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Capitaluri</span>
            <span className="text-900 font-medium text-sm">{company.financial.capital} RON</span>
            <span className="text-900 font-medium text-sm">
              <i className="pi pi-arrow-up text-green-500 " />
            </span>
            <span className="text-900 font-medium text-sm">
              {company.siscoreOfficial.capital} SiScore
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default CompanyItemProfileFinance;
