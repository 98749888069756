import React from "react";
import useCompany from "../../hooks/useCompany";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";

const CompaniesListItemCard = ({company}) => {
  const {deleteCompany, companyEditHandler} = useCompany();

  return (
    <div className="col-12 md:col-6 lg:col-4">
      <div className="surface-card shadow-2 p-3 border-round">
        <div className="flex justify-content-between mb-3">
          <div>
            <span className="block text-green-500 font-medium mb-3">{company.name}</span>
            <div className="text-900 font-medium text-xl">
              <span className="text-500">SiScore:</span> {company.siscoreGlobal}
            </div>
          </div>
          <Link to={`/auth/companies/edit/${company.id}`}>
            <Button
              onClick={() => companyEditHandler(company.id)}
              icon="pi pi-pencil"
              className="p-button p-button-text p-button-icon-only bg-blue-100
              border-round"></Button>
          </Link>
        </div>
        <div className="flex justify-content-between pt-3">
          <Button
            label={company.views}
            icon="pi pi-eye"
            className="p-button-text p-button-secondary w-6 mr-2"
          />
          <Button
            label={company.likes}
            icon="pi pi-heart"
            className="p-button-text p-button-secondary w-6 ml-2"
          />
        </div>
        <ul className="list-none m-0 p-0">
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Proiecte</span>
            <span className="text-900 font-medium text-sm">{company.projectsNumber}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Anunturi</span>
            <span className="text-900 font-medium text-sm">{company.advertsNumber}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Angajari</span>
            <span className="text-900 font-medium text-sm">{company.jobsNumber}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Licitatii</span>
            <span className="text-900 font-medium text-sm">{company.auctionsNumber}</span>
          </li>
        </ul>
        <div className="flex justify-content-between pt-3">
          <Button
            onClick={() => deleteCompany(company.id)}
            // label="Sterge"
            icon="pi pi-trash"
            className="p-button-text p-button-secondary bg-red-100 w-4 ml-2"
          />
          <Button
            onClick={() => companyEditHandler(company.id)}
            // label="Detalii"
            icon="pi pi-database"
            className="p-button-text p-button-secondary bg-gray-100 w-4 mr-2"
          />
        </div>
      </div>
    </div>
  );
};
export default CompaniesListItemCard;
