import React from "react";
import CompanyItemProfileGeneral from "../../../components/company/CompanyItemProfileGeneral";
import CompanyItemProfileFinance from "../../../components/company/CompanyItemProfileFinance";
import CompanyItemProfileLegal from "../../../components/company/CompanyItemProfileLegal";
import CompanyItemProfileAssociates from "../../../components/company/CompanyItemProfileAssociates";

const CompanyItem = () => {
  return (
    <div className="grid">
      <CompanyItemProfileGeneral />
      <CompanyItemProfileFinance />
      <CompanyItemProfileLegal />
      <CompanyItemProfileAssociates />
    </div>
  );
};

export default CompanyItem;
