import React, {createContext, useEffect, useState} from "react";
import advertData from "../services/advertService";
import useCommonContext from "../hooks/useCommonContext";
import {useNavigate, useParams} from "react-router-dom";
import EditProduct from "../components/advert/EditProduct";
import EditService from "../components/advert/EditService";
import EditTender from "../components/advert/EditTender";
import EditJob from "../components/advert/EditJob";

const AdvertContext = createContext({});

export const AdvertProvider = ({children}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {setFetchErrMsg, setIsLoading, setErrMsg, errRef, setActiveTab, setShowDetails} =
    useCommonContext();

  const advertInitial = {
    id: "",
    name: "Denumire anunt",
    description: "",
    descriptionShort: "Scurta descriere, 10-15 cuvinte ",
    contact: {
      county: "Iasi",
      city: "Iasi",
      address: "Adressa ANAF",
      phone: "+40 232 123456",
      email: "info@axe.ro",
      web: "Axe-business.ro",
    },
    tags: [],
    actual: false,
    offer: true,
    type: "",
    views: 0,
    likes: 0,
    created: "21.11.2022",
    edited: "25.11.2022",

    product: {
      subcategory: "",
      price: 100,
      warranty: 3,
      valability: "",
      storageAddress: "",
      minBuy: 1,
      age: 2,
      img: [
        {
          path: "https://loremflickr.com/320/240/space?random=2",
          title: "Item NO",
          text: "Image description lorem ipsum",
        },
        {
          path: "https://loremflickr.com/320/240/space?random=3",
          title: "Item NO",
          text: "Image description short",
        },
        {
          path: "https://loremflickr.com/320/240/space?random=1",
          title: "Item NO",
          text: "Image description short",
        },
        {
          path: "https://loremflickr.com/320/240/space?random=4",
          title: "Item NO",
          text: "Image description short",
        },
      ],
      details: [
        {name: "Manoperă inclusă", key: "labourIncluded"},
        {name: "Estimare manoperă", key: "labourEstimation"},
        {name: "Propgamare", key: "labourSchedule"},
        {name: "Factura", key: "billEmit"},
        {name: "Livrare", key: "delivery"},
        {name: "Livrare gratuită", key: "deliveryFree"},
        {name: "Produs nou", key: "isNew"},
        {name: "Lichidare stoc", key: "sale"},
      ],
    },
    service: {
      subcategory: "",
      price: 100,
      bill: true,
      term: "",
      movementIncluded: true,
      movementEstimation: true,
      movementSchedule: true,
      minBuy: 10,
      warranty: 10,
      img: [
        {
          path: "https://loremflickr.com/320/240/space?random=2",
          title: "Item NO",
          text: "Image description lorem ipsum",
        },
        {
          path: "https://loremflickr.com/320/240/space?random=4",
          title: "Item NO",
          text: "Image description short",
        },
      ],
      details: [
        {name: "Deplasare inclusă", key: "movementIncluded"},
        {name: "Estimare cost", key: "costEstimation"},
        {name: "Propgamare", key: "Schedule"},
        {name: "Factura", key: "billEmit"},
        {name: "Livrare", key: "delivery"},
        {name: "Livrare gratuită", key: "deliveryFree"},
      ],
    },
    tender: {
      subcategory: "",
      type: "",
      price: 240,
      step: 10,
      startDate: "",
      endDate: "",
      victoryConditions: "",
      details: [
        {name: "Detalii", key: "tender1"},
        {name: "Detalii2", key: "tender2"},
      ],
    },
    job: {
      corList: "",
      position: "",
      type: "",
      studies: "",
      salary: "",
      term: "",
      experience: 2,
      details: [
        {name: "Oferta", key: "offer"},
        {name: "Remote", key: "remote"},
        {name: "Part-time", key: "partTime"},
      ],
    },
  };
  const [advert, setAdvert] = useState(advertInitial);
  const [adverts, setAdverts] = useState([]);
  const [filteredAdverts, setFilteredAdverts] = useState([]);
  const types = [
    {name: "Produs", key: "product"},
    {name: "Serviciu", key: "service"},
    {name: "Licitație", key: "tender"},
    {name: "Job", key: "job"},
  ];
  const [selectedType, setSelectedType] = useState({name: "", key: ""});
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [tags, setTags] = useState([]);

  const newAdvertHandler = (type) => {
    setAdvert({...advertInitial, type: type});
    setSelectedType(type);
    navigate("/auth/new-advert");
  };

  const advertEditHandler = (id) => {
    setShowDetails(true);
    fetchAdvert(id);
  };

  const inputChangeHandler = (event) => {
    const {name, value} = event.target;
    setAdvert({...advert, [name]: value});
  };
  const inputChangeHandlerChild = (event, key) => {
    const {name, value} = event.target;
    setAdvert((advert) => {
      return {
        ...advert,
        [key]: {
          ...advert[key],
          [name]: value,
        },
      };
    });
  };

  const fetchAdvert = async (id) => {
    try {
      const response = await advertData.get(id);
      setAdvert(response.data);
      setFetchErrMsg(null);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        setFetchErrMsg(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAdverts = async () => {
    try {
      const response = await advertData.getAll();
      setAdverts(response.data);
      setFetchErrMsg(null);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        setFetchErrMsg(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("/adverts/edit")) {
      let params = window.location.pathname.split("/");
      let id = params.slice(-1);
      fetchAdvert(+id);
    }
    setTimeout(() => {
      fetchAdverts();
    }, 500);
  }, []);

  const addAdvert = async (e) => {
    e.preventDefault();
    for (var k in advert) {
      if (types.filter((type) => type.key === k).length > 0 && k !== advert.type.key) {
        delete advert[k];
      }
    }
    try {
      await advertData.create(advert);
      fetchAdverts();
      setShowDetails(true);
      navigate("/auth/adverts");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing company");
      } else {
        setErrMsg("Fetch failed");
      }
      errRef.current.focus();
    }
    console.log(advert);
  };

  const updateAdvert = async (e) => {
    e.preventDefault();
    try {
      await advertData.update(advert.id, advert);
      fetchAdverts();
      navigate("/auth/adverts");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing company");
      } else {
        setErrMsg("Fetch failed");
      }
      errRef.current.focus();
    }
  };

  const deleteAdvert = async (id) => {
    try {
      await advertData.remove(id);
      setShowDetails(false);
      fetchAdverts();
    } catch (err) {
      console.log(`Error:${err.message}`);
    }
  };

  const filterByType = (type) => {
    let filteredAdverts = adverts.filter((advert) => advert.type.key === type);
    setFilteredAdverts(filteredAdverts);
    setShowDetails(true);
  };

  const typeSelect = (e) => {
    setSelectedType(e.value);
    setAdvert({...advert, type: e.value});
  };

  const showTypeProps = () => {
    switch (selectedType.key) {
      case "product":
        return <EditProduct />;
      case "service":
        return <EditService />;
      case "tender":
        return <EditTender />;
      case "job":
        return <EditJob />;
      default:
        return <div className="text-blue-300 mt-2">Alege tipul anunțului</div>;
    }
  };

  const detailCheckHandler = (e) => {
    let selected = [...selectedDetails];
    if (e.checked) selected.push(e.value);
    else selected = selected.filter((detail) => detail.key !== e.value.key);
    setSelectedDetails(selected);
    // setAdvert((advert) => {
    //   return {
    //     ...advert,
    //     [advert.type.details]: {
    //       ...advert[advert.type.details],
    //       selected: selectedDetails,
    //     },
    //   };
    // });
    console.log("selected__", selected);
    console.log("selectedDetails__", selectedDetails);
    // ===
    // this.setState(prevState => ({
    //   ...prevState,
    //   someProperty: {
    //     ...prevState.someProperty,
    //     someOtherProperty: {
    //       ...prevState.someProperty.someOtherProperty,
    //       anotherProperty: {
    //         ...prevState.someProperty.someOtherProperty.anotherProperty,
    //         flag: false
    //       }
    //     }
    //   }
    // }))
    // ===
    setAdvert((advert) => ({
      ...advert,
      [advert.type.key]: {
        ...advert[advert.type.key],
        // details: {
        //   ...advert[advert.type.key].details,

        ___QWERTY___: selectedDetails,
        // },
      },
    }));
    console.log(advert);
    console.log("selected", selected);
    console.log("selectedDetails", selectedDetails);
  };

  function handleKeyDownTag(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    let tags = advert.tags;
    tags.push(value);
    setAdvert({...advert, tags: tags});
    e.target.value = "";
  }

  function removeTag(index) {
    let tags = advert.tags;
    let newTags = tags.filter((item, key) => key !== index);
    setAdvert({...advert, tags: newTags});
  }

  return (
    <AdvertContext.Provider
      value={{
        advertInitial,
        advert,
        setAdvert,
        types,
        selectedType,
        setSelectedType,
        adverts,
        filteredAdverts,
        setAdverts,
        selectedDetails,
        setSelectedDetails,
        tags,
        setTags,
        filterByType,
        newAdvertHandler,
        inputChangeHandler,
        inputChangeHandlerChild,
        advertEditHandler,
        detailCheckHandler,
        addAdvert,
        deleteAdvert,
        updateAdvert,
        showTypeProps,
        handleKeyDownTag,
        removeTag,
        typeSelect,
      }}>
      {children}
    </AdvertContext.Provider>
  );
};
export default AdvertContext;
