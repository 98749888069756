import React from "react";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import useAdvert from "../../hooks/useAdvert";
import AdvertsList from "../../pages/auth/adverts/AdvertsList";
import useCommonContext from "../../hooks/useCommonContext";

const AdvertsListCategoryCard = ({type}) => {
  const {advert, adverts, newAdvertHandler, advertCategoryViewHandler, filterByType, types} =
    useAdvert();
  const {setShowDetails, showDetails} = useCommonContext();

  return (
    <div className="col-12 md:col-6 lg:col-3">
      <div className="surface-card shadow-2 p-3 border-round">
        <div className="flex justify-content-between mb-3">
          <div>
            <span className="block text-green-500 font-medium mb-3">{type.name}</span>
            <div className="text-900 font-medium text-xl">
              {/* // TODO find nr of actual/archieved items in category*/}
              <span className="text-500">Actuale:</span> 2
            </div>
          </div>
        </div>
        <span className="text-gray-500 font-medium">
          {" "}
          <span className="text-500">Arhivate</span> 12{" "}
        </span>
        <div className="flex justify-content-between pt-3">
          <Button
            onClick={() => newAdvertHandler(type)}
            // label="Adauga"
            icon="pi pi-plus-circle"
            className="p-button-text p-button-secondary bg-blue-100 w-4 ml-2"
          />
          <Button
            // onClick={() => advertCategoryViewHandler(advert.type)}
            onClick={() => filterByType(type.key)}
            // label="Detalii"
            icon="pi pi-database"
            className="p-button-text p-button-secondary bg-gray-100 w-4 mr-2"
          />
        </div>
      </div>
    </div>
  );
};
export default AdvertsListCategoryCard;
