import React from "react";
import {TabView, TabPanel} from "primereact/tabview";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import Title from "../../../components/ui/Title";
import useProject from "../../../hooks/useProject";
import {InputTextarea} from "primereact/inputtextarea";
import {FileUpload} from "primereact/fileupload";
import useCommonContext from "../../../hooks/useCommonContext";

function EditProject() {
  const {activeTab, setActiveTab} = useCommonContext();
  const {project, inputChangeHandler, inputChangeHandlerClientCompany, updateProject, addProject} =
    useProject();

  return (
    <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
      <div
        className="border-blue-600 mb-3 lg:mb-0"
        style={{borderLeft: "8px solid"}}>
        {project.id ? <Title title="Modificare proiect" /> : <Title title="Adaugare proiect" />}
      </div>
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Client">
          <div className="flex flex-column md:flex-row flex-between w-full p-3">
            <div className="md:w-4 p-fluid pr-0 md:pr-6">
              <div className="field">
                <label
                  htmlFor="CUI"
                  className="block text-900 font-medium mb-2">
                  CUI:
                </label>
                <InputText
                  id="cui"
                  type="number"
                  autoComplete="off"
                  name="cui"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  value={project.clientCompany.cui}
                  // required
                  className="w-full mb-3 p-3"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="role"
                  className="block text-900 font-medium mb-2">
                  Calitate firma(beneficiar/prestator)
                </label>
                <InputText
                  id="role"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="role"
                  value={project.clientCompany.role}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="contactPerson"
                  className="block text-900 font-medium mb-2">
                  Persoana de contact
                </label>
                <InputText
                  id="contactPerson"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="contactPerson"
                  value={project.clientCompany.contactPerson}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
            </div>
            <div className="md:w-4 p-fluid pr-0 md:pr-6">
              <div className="field">
                <label
                  htmlFor="clientCity"
                  className="block text-900 font-medium mb-2">
                  Localitate
                </label>
                <InputText
                  id="clientCity"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="city"
                  value={project.clientCompany.city}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="clientCompanyContactPhone"
                  className="block text-900 font-medium mb-2">
                  Telefon
                </label>
                <InputText
                  id="clientCompanyContactPhone"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="contactPhone"
                  value={project.clientCompany.contactPhone}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="clientCompany"
                  className="block text-900 font-medium mb-2">
                  Denumire companie:
                </label>
                <InputText
                  type="text"
                  id="clientCompany"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="name"
                  value={project.clientCompany.name}
                  // required
                  className="w-full mb-3 p-3"
                />
              </div>
            </div>
            <div className="md:w-4 p-fluid pr-0 md:pr-6">
              <div className="field">
                <label
                  htmlFor="clientCommercialRegister"
                  className="block text-900 font-medium mb-2">
                  Registrul comertului:
                </label>
                <InputText
                  type="text"
                  id="clientCommercialRegister"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="commercialRegister"
                  value={project.clientCompany.commercialRegister}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="clientCompanyNote"
                  className="block text-900 font-medium mb-2">
                  Note
                </label>
                <InputText
                  id="clientCompanyNote"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="note"
                  value={project.clientCompany.note}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="clientCompanyEmail"
                  className="block text-900 font-medium mb-2">
                  Email
                </label>
                <InputText
                  id="clientCompanyEmail"
                  type="email"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandlerClientCompany(e)}
                  name="contactEmail"
                  value={project.clientCompany.contactEmail}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <Button
                label="Continua"
                icon="pi pi-thumbs-up"
                className="w-auto my-2"
                onClick={() => setActiveTab(1)}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel header="Proiect">
          <div className="flex flex-column md:flex-row flex-between w-full p-3">
            <div className="md:w-4 p-fluid pr-0 md:pr-6">
              <div className="field">
                <label
                  htmlFor="projectName"
                  className="block text-900 font-medium mb-2">
                  Denumire proiect
                </label>
                <InputText
                  id="projectName"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="name"
                  value={project.name}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="projectValue"
                  className="block text-900 font-medium mb-2">
                  Valoare proiect, lei
                </label>
                <InputText
                  id="projectValue"
                  type="number"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="value"
                  value={project.value}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="projectStartEnd"
                  className="block text-900 font-medium mb-2">
                  An incepere - an terminare
                </label>
                <InputText
                  id="projectStartEnd"
                  type="date"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="startEnd"
                  value={project.startEnd}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
            </div>
            <div className="md:w-4 p-fluid pr-0 md:pr-6">
              <div className="field">
                <label
                  htmlFor="projectCity"
                  className="block text-900 font-medium mb-2">
                  Localitate proiect
                </label>
                <InputText
                  id="projectCity"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="city"
                  value={project.city}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="projectStage"
                  className="block text-900 font-medium mb-2">
                  Stadiu proiect
                </label>
                <InputText
                  id="projectStage"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="stage"
                  value={project.stage}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="projectRoleIn"
                  className="block text-900 font-medium mb-2">
                  Calitate in proiect
                </label>
                <InputText
                  id="projectRoleIn"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="roleIn"
                  value={project.roleIn}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
            </div>
            <div className="md:w-4 p-fluid pr-0 md:pr-6">
              <div className="field">
                <label
                  htmlFor="projectContactPerson"
                  className="block text-900 font-medium mb-2">
                  Persoana de contact in proiect
                </label>
                <InputText
                  id="projectContactPerson"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="contactPerson"
                  value={project.contactPerson}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="projectContactPhone"
                  className="block text-900 font-medium mb-2">
                  Telefon
                </label>
                <InputText
                  id="projectContactPhone"
                  type="number"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="contactPhone"
                  value={project.contactPhone}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
              <div className="field">
                <label
                  htmlFor="projectContactEmail"
                  className="block text-900 font-medium mb-2">
                  Email
                </label>
                <InputText
                  id="projectContactEmail"
                  type="email"
                  autoComplete="off"
                  onChange={(e) => inputChangeHandler(e)}
                  name="contactEmail"
                  value={project.contactEmail}
                  // required
                  className="py-3 px-2 text-lg"
                />
              </div>
            </div>
          </div>
          <img
            src="https://loremflickr.com/320/240/space?random=2"
            alt="projectName"
          />
          <FileUpload
            mode="basic"
            name="de"
            url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            // onUpload={projectImgUpload}
          />
          {/* TODO add action for uploading file*/}
          <p>
            <label
              htmlFor="projectContactEmail"
              className="block text-900 font-medium mb-2">
              Descriere scurta
            </label>
            <InputTextarea
              autoResize
              onChange={(e) => inputChangeHandler(e)}
              name="brief"
              value={project.brief}
              rows={5}
              cols={50}
            />
          </p>
          <p>
            <label
              htmlFor="projectContactEmail"
              className="block text-900 font-medium mb-2">
              Detalii
            </label>
            <InputTextarea
              autoResize
              onChange={(e) => inputChangeHandler(e)}
              name="details"
              value={project.details}
              rows={5}
              cols={50}
            />
          </p>
          {project.id ? (
            <Button
              label="Modifică proiect"
              icon="pi pi-thumbs-up"
              className="w-auto"
              onClick={updateProject}
            />
          ) : (
            <Button
              label="Adauga proiect"
              icon="pi pi-thumbs-up"
              className="w-auto"
              onClick={addProject}
            />
          )}
        </TabPanel>
      </TabView>
    </div>
  );
}

export default EditProject;
