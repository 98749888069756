import {Ripple} from "primereact/ripple";
import {MultiSelect} from "primereact/multiselect";
import {ToggleButton} from "primereact/togglebutton";
import {useState} from "react";
import {Divider} from "primereact/divider";

const Home = () => {
  function productComponent(element) {
    return (
      <div
        className="col-12 md:col-6 lg:col-3 mb-5 lg:mb-0"
        key={element.id}>
        <div className="mb-3 relative">
          <img
            src="https://picsum.photos/500/400"
            alt="Logo"
            className="w-full"
          />
          <button
            type="button"
            className="p-ripple border-1 border-white border-round py-2 px-3 absolute\r
             bg-black-alpha-30 text-white inline-flex align-items-center justify-content-center\r
             hover:bg-black-alpha-40 transition-colors transition-duration-300 cursor-pointer"
            style={{
              bottom: "1rem",

              left: "1rem",
              width: "calc(100% - 2rem)",
            }}>
            <i className="pi pi-shopping-cart mr-3 text-base" />
            <span className="text-base">Vezi mai mult</span>
            <Ripple />
          </button>
        </div>
        <div className="flex flex-column align-items-center">
          <span className="text-xl text-900 font-medium mb-3">{element.name}</span>
          <span className="text-xl text-900 mb-3">$150.00</span>
        </div>
      </div>
    );
  }

  let [selectedBrands, setSelectedBrands] = useState("");
  let [selectedColors, setSelectedColors] = useState("");
  let [selectedPrice, setSelectedPrice] = useState("");
  let [checked1, setChecked1] = useState("");
  let [checked2, setChecked2] = useState("");
  let brands = [
    {name: "Constructii", value: "NY"},
    {name: "Electricieni", value: "RM"},
    {name: "Sudori", value: "LDN"},
    {name: "Instalatori", value: "IST"},
    {name: "dasdas", value: "PRS"},
  ];

  let counties = [
    {name: "Iasi", value: "NY"},
    {name: "Vaslui", value: "RM"},
    {name: "Bacau", value: "LDN"},
    {name: "Suceava", value: "IST"},
    {name: "Botosani", value: "PRS"},
  ];
  const prices = [
    {label: "<100", value: 200},
    {name: "100-400", value: "RM"},
    {name: "1000-10000", value: "LDN"},
    {name: "10", value: "IST"},
    {name: "1000", value: "PRS"},
  ];

  let products = [
    {
      id: 0,
      name: "Prod0",
    },
    {
      id: 1,
      name: "Prod1",
    },
    {
      id: 2,
      name: "Prod2",
    },
    {
      id: 3,
      name: "Prod3",
    },
    {
      id: 4,
      name: "Prod4",
    },
    {
      id: 5,
      name: "Prod5",
    },
    {
      id: 6,
      name: "Prod6",
    },
    {
      id: 7,
      name: "Prod7",
    },
  ];

  return (
    <div>
      <div className="surface-section px-4  md:px-6 lg:px-8">
        <Divider className="w-full border-gray-200" />
        <div className="grid grid-nogutter align-items-center">
          <MultiSelect
            options={brands}
            value={selectedBrands}
            onChange={(e) => setSelectedBrands(e.value)}
            placeholder="Categorie"
            optionLabel="name"
            filter
            maxSelectedLabels="2"
            selectedItemsLabel={`${selectedBrands && selectedBrands.length} brands selected`}
            className="flex-auto lg:flex-1 mb-3 lg:mt-0 w-full mr-0 lg:mr-4 text-900"
          />
          <MultiSelect
            options={counties}
            value={selectedColors}
            onChange={(e) => setSelectedColors(e.value)}
            placeholder="Judet"
            optionLabel="name"
            filter
            maxSelectedLabels="2"
            selectedItemsLabel={`${selectedColors && selectedColors.length} colors selected`}
            className="flex-auto lg:flex-1 mb-3 lg:mt-0 w-full mr-0 lg:mr-4 text-900"
            itemTemplate={(county) => (
              <div className="flex align-items-center">
                <div className="text-900 ml-2">{county.name}</div>
              </div>
            )}
          />
          <MultiSelect
            options={prices}
            value={selectedPrice}
            onChange={(e) => setSelectedPrice(e.value)}
            placeholder="Pret"
            optionLabel="range"
            filter
            maxSelectedLabels="2"
            selectedItemsLabel={`${selectedPrice && selectedPrice.length} prices selected`}
            className="flex-auto lg:flex-1 mb-3 lg:mt-0 w-full mr-0 lg:mr-4 text-900"
          />
          <ToggleButton
            checked={checked1}
            onChange={(e) => setChecked1(e.value)}
            onLabel="Negociabil"
            offLabel="Pret fix"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            className="mb-3 lg:mt-0 mr-4 flex-shrink-0 w-12rem"
          />
          <ToggleButton
            checked={checked2}
            onChange={(e) => setChecked2(e.value)}
            onLabel="In stoc"
            offLabel="Rezervare"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            className="mb-3 lg:mt-0 mr-4 flex-shrink-0 w-9rem"
          />
          <button
            tabIndex="0"
            className="p-ripple cursor-pointer flex align-items-center mb-3 lg:mt-0 text-900">
            Clear All
            <Ripple />
          </button>
          <div className="col-12">
            <div className="grid mt-4">{products.map((element) => productComponent(element))}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
