import {createContext, useRef, useState} from "react";

const CommonContext = createContext({});

export const CommonProvider = ({children}) => {
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [fetchErrMsg, setFetchErrMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  return (
    <CommonContext.Provider
      value={{
        userRef,
        errRef,
        errMsg,
        setErrMsg,
        fetchErrMsg,
        setFetchErrMsg,
        isLoading,
        setIsLoading,
        activeTab,
        setActiveTab,
        showDetails,
        setShowDetails,
      }}>
      {children}
    </CommonContext.Provider>
  );
};
export default CommonContext;
