import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import Title from "../../../components/ui/Title";
import ProjectsList from "./ProjectsList";
import useProject from "../../../hooks/useProject";
import ProjectItem from "./ProjectItem";
import useCommonContext from "../../../hooks/useCommonContext";

const Projects = () => {
  const {projects, newProjectHandler, project} = useProject();
  const {showDetails} = useCommonContext();

  let content = <ProjectsList />;
  if (!projects) {
    content = (
      <div className="surface-card border-round shadow-2 p-4">
        <div className="text-900 font-medium mb-3 text-xl">Nu aveti proiecte adaugate</div>
        <p className="mt-0 mb-4 p-0 line-height-3">
          Proiectele sunt importante pentru formarea scorului companiei
        </p>
        <Link
          to="/auth/new-project"
          className="p-ripple flex align-items-center cursor-pointer"
          style={{textDecoration: "none"}}>
          <Button label="Adaugare proiect" />
        </Link>
      </div>
    );
  }

  return (
    <div className="w-full surface-section px-4 py-5 md:px-6 lg:px-8">
      <div className="flex justify-content-between">
        <Title title="Proiecte finalizate" />
        <div
          className="flex inline align-items-center justify-content-center bg-blue-100 border-round"
          style={{width: "2.5rem", height: "2.5rem"}}>
          <Link
            to="/auth/new-project"
            onClick={newProjectHandler}
            className="p-ripple flex align-items-center cursor-pointer ">
            <i className="pi pi-plus-circle text-blue-500 text-xl" />
          </Link>
        </div>
      </div>
      <div className="w-full flex justify-content-center align-items-center">{content}</div>
      {showDetails && (
        <ProjectItem
          project={project}
          id={project.id}
        />
      )}
    </div>
  );
};

export default Projects;
