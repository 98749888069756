import React from "react";
import useCompany from "../../hooks/useCompany";

const CompanyItemProfileLegal = () => {
  const {company} = useCompany();

  return (
    <div className="col-12 md:col-6 p-3">
      <div
        className="surface-card shadow-2 border-rounded p-3"
        style={{borderRadius: "6px"}}>
        <div className="text-xl font-medium text-900 mb-2">Profil Juridic</div>
        <ul className="list-none m-0 p-0">
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-600 font-medium text-sm">Organ</span>
            <span className="text-600 font-medium text-sm">Total aparitii</span>
            <span className="text-600 font-medium text-sm">Ultima aparitie</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-900 font-medium text-sm ">Monitorul Oficial</span>
            <span className="text-900 font-medium text-sm ">{company.legal.monitorOccurs}</span>
            <span className="text-900 font-medium text-sm ">{company.legal.monitorLast}</span>
          </li>
          <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
            <span className="text-900 font-medium text-sm ">Instante de judecata</span>
            <span className="text-900 font-medium text-sm ">{company.legal.justiceOccurs}</span>
            <span className="text-900 font-medium text-sm ">{company.legal.justiceOccurs}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default CompanyItemProfileLegal;
